<template>
  <div class="chatFixWrap" :style="{'bottom': item.bottom + 'px'}">
    <img class="chatImg" :src="item.imgUrl" />
    <div class="chatText" :dataKey="item.zx_key" :style="{'backgroundColor': item.btnBgColor}">{{item.text}}</div>
  </div>
</template>
<style scoped>
.chatFixWrap {
  /* border: 1px solid #f00; */
  position: absolute;
  right: 0;
  bottom: 300px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.chatFixWrap .chatImg {
  width: 70px;
  height: 70px;
  overflow: hidden;
  display: block;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.chatText{
  width: 90px;
  height: 25px;
  /* background: #20a0ff; */
  color: #fff;
  line-height: 25px;
  text-align: center;
  font-size: 12px;
  border-radius: 5px;
}
</style>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    
  }
}
</script>
