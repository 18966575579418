<template>
	<div style="text-align: left;font-size: 14px;">
		<div style="padding: 10px 20px;background: #eee;">标识解析</div>
		<div style="line-height: 32px;border-bottom: 1px solid #eee;padding: 0 20px;">
			<div>标识</div>
			<div>{{item.handle}}</div>
		</div>
		<div style="line-height: 32px;border-bottom: 1px solid #eee;padding: 0 20px;">
			<div>照片</div>
			<img :src="item.url" alt="" width="240px" height="300px">
		</div>
		<div v-for="(listItem, index) in item.info" :key="index" style="line-height: 32px;border-bottom: 1px solid #eee;padding: 0 20px;">
			<div>{{listItem.lable}}</div>
			<div>{{listItem.text}}</div>
		</div>
		<div class="ewmImgBox2">
		  <vue-qr
		    :size="120"
		    :margin="0"
		    :auto-color="true"
		    :dot-scale="1"
		    :text="item.erwm"
		  />
			<div>{{item.handle}}</div>
		</div>
		<div style="text-align: center;"> 本标识由国家工业互联网标识解析二级节点（合肥）分配 </div>
	</div>
</template>

<script>
	import VueQr from 'vue-qr'
	export default {
		components: {
		  VueQr
		},
		data() {
			return {
				
			}
		},
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		methods: {
			
		},
		computed: {
			
		},
	}
</script>

<style>
	.ewmImgBox2 {
		margin: 10px 0;
		text-align: center;
	}
</style>