<template>
  <div>
    <div class="diymenu">
      <div class="item goShopButton" :style="{backgroundColor: item.rtBtnBgColor, width: `${item.rtBtnWidth}%`, color: item.rtTextColor}">
        <img class="cart_icon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/221646961587_.pic.jpg" />
        <span>{{item.lfBtnText?item.lfBtnText : '商城'}}</span>
      </div>

      <div class="item kfButton" :style="{backgroundColor: item.lfBtnBgColor, width: `${item.lfBtnWidth}%`, color: item.lfTextColor}" :dataKey="item.zx_key" >
        <img class="zx_icon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/251646961619_.pic.jpg" />
        <div>{{item.rtBtnText ? item.rtBtnText : '咨询'}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
<style>

.diymenu{
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  width: 100%;
  height: 50px;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.zx_icon{
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.cart_icon{
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.goShopButton{
  display: flex; 
  justify-content: center; 
  align-items: center;
  /* width: 50%; */
  /* background-color: #c22131; */
  /* color: #ffffff; */
  line-height: 25px;
  font-size: 14px;
}
.kfButton{
  width: 20%;
  display: flex;
  justify-content: center; 
  align-items: center;
  /* width: 50%; */
  /* background: #fff; */
  /* flex-direction: column;*/
}
</style>
