<template>
 <div>
  <div v-for="(videoItem, index) in item.videoList" :key="videoItem.url + index">
   <video class="myVideo" 
    controls="controls" 
    :src="videoItem.url" 
    :width="videoItem.videoWidth? videoItem.videoWidth : '100%'"
    :height="videoItem.videoHeight ? videoItem.videoHeight : 'auto'">
  </video>
  </div>
 </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
}
</script>