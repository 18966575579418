<template>
 <div :style="styleObject">
   <div class="pdfBtn" :style="{padding: `${item.paddTop}px ${item.paddLeft}px`, textAlign: item.align,color:item.titleColor}" @click="downPdf()">{{ item.title }}</div>
 </div>
</template>

<script>
export default {
  name: "PdfShow",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleObject() {
      return {
        padding: this.item.paddTop + 'px' + ' ' + this.item.paddLeft + 'px',
        backgroundColor: this.item.bgColor
      }
    },
    imgStyle() {
      return (n, item) => {
        var wid
        var pos
        var str
        pos = item.align
        if (n.width) {
          wid = n.width
        } else {
          wid = '100%'
        }
        str = 'width: ' + wid
        if (pos === 'left') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'right') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'center') {
          console.log('pos', pos)
          str = `width: ${wid};margin: 0 auto;`
        }

        return str
      }
      /* return {
        width:(picItem.width?picItem.width:'100%'),
        float:(picItem.width?'left':'none')
      }*/
    }
  },
  methods: {
    getItem() {
      console.log(this.item)
    },
    downPdf() {
      // var link = document.createElement('a')//构建一个a标签
      // link.href = this.item.src//把路径给它
      // link.download = this.item.title//下载的文件名字
      // link.click()
     // window.open(this.item.src,'_blank')
    }
  }
}
</script>

<style scoped>
.pdfBtn{
  min-height: 20px;
  color: #000;
}
</style>
