<template>
  <div :style="[{backgroundColor:item.bgColor},{ padding: item.paddTop+'px' +' '+ item.paddLeft+'px' },{ backgroundImage: `url(${item.bgImg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
    <div class="myLogo"><img :src="item.logo" /></div>
    <div class="proBox"><img :src="item.proImg.url" :style="{ 'width': item.proImg.width, 'top': item.top + '%'}" class="iconImg"/></div>  
    <div class="titleBox" :style="{'color': item.titleColor, 'fontSize': item.titleSize+'px',  'backgroundImage': `url(${item.titleBg})`, 'backgroundRepeat': 'no-repeat', 'backgroundSize': '100% 100%'}">
      {{ item.title }}
    </div>
    <div class="proContent">
        <div class="proItemWrap" v-for="item in item.list" :key="item.content">
          <template v-if="item.isImg == 1">
            <div class="proItemImgBox">
              <img class="proItemImg" :src="item.titleImg" :style="{ 'width': item.titleImgWidth}"/>
            </div>
          </template>
          <template v-if="item.isImg == 2">
           <div class="proItemTitle">{{item.title}}</div>
          </template>

          <div class="proItemCon">{{item.content}}</div>
        </div>
    </div>

    <div>
      <div class="codeWrap">
        <div class="codeInner" :style="{backgroundImage: `url(${item.codeBgImg ? item.codeBgImg.url : ''})`}">
          <div style="margin-top: 30px">
          <vue-qr
                  :size="130"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="codeUrl"
                />       
            </div>   
        </div>
      </div>

      <div class="handleInfo">
        <img class="gyIcon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/3f00d209a93717cdbf32596e0fc1796c.png" />
        <div>
          <div>工业互联网标识码：</div>
          <div class="handleCode">86.122.2/yjcs001</div>
        </div>
      </div>
      <div class="bottomInfo">由国家工信部授权工业互联网标识解析二级节点（太原）签发</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.logo{
  height: 30px;
}
.proBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.titleBox{
  width: 85%;
  margin: 0 auto;
  padding: 15px;
  font-family: '宋体';
  letter-spacing: 2px;
  margin-top: 30px;
}
.myLogo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.myLogo img{
  height: 30px;
  margin-top: 20px;
  margin-left: 10px;
}
.proContent .proItemImgBox{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
}
.proContent .proItemTitle{
  font-size: 20px;
  color: #fff;
  text-align: left;
  padding: 20px;
}
.proContent .proItemCon{
  font-size: 14px;
  color: #fff;
  padding: 0 20px;
  line-height: 20px;
  text-align: left;
}
.codeWrap{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}
.codeInner{
  background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/804da128a8966240109af1f4be60d31c.png') top center no-repeat;
  // background-image: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/804da128a8966240109af1f4be60d31c.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.handleInfo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.handleInfo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.handleCode{
  background: #82A8FF;
  margin-top: 2px;
}
.bottomInfo{
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 20px;
}
.proItemWrap{
  margin-bottom: 20px;
}
</style>
<script>
import VueQr from 'vue-qr'
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    VueQr
  },
  data() {
    return {
      codeUrl: '',
    }
  },
  computed: {
    // listWrapObject() {
    //   return {

    //   }
    // }
  },
}
</script>

