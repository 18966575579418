<template>
  <div :style="[{backgroundColor:data.bgColor},{ padding: data.paddTop+'px' +' '+ data.paddLeft+'px' },{ backgroundImage: `url(${data.boxBg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
    <div class="ewmBox" :style="{backgroundColor:data.bgColor}">
      <div id="statusBox" class="statusBox">
        <img src="../../../assets/images/coustomPage/lan/sta_product.png" alt="" class="staProImg">
        <div class="openForBox">
          <img src="../../../assets/images/coustomPage/lan/sta_open.png" alt="" class="staOpenImg">
          <div class="openWords">恭喜您的产品是正品</div>
          <div class="openTips">产品已被开启，请注意甄别</div>
        </div>

        <div class="openUnForBox">
          <img
            src="../../../assets/images/coustomPage/lan/sta_unopened.png"
            alt=""
            class="staUnOpenImg"
          >
          <div class="openWords">恭喜您的产品是正品</div>
          <div class="openTips">产品未开启可放心使用</div>
        </div>
      </div>

      <div id="ewmTitleFen" class="ewmTitleFen">anti-counterfeit qr code</div>
      <div id="ewmTitle" class="ewmTitle" style="color: #000">防伪二维码</div>
      <div id="ewmShowBox" class="showEwmBox">
        <div class="ewmLeftFiveBox">
          <div class="imgFourLeft">
            <img
              :src="data.scanBg"
              alt=""
              class="phoneFiveImg"
            >
            <div style="clear: both" />
          </div>
          <div class="imgFourRight">
            <div class="setEwmBox" style="left: 1px;right: 0;top: 25px;position: relative;">
              <div id="erweima_{$diyitemid}" class="prefixEwmImg" style="width: 115px;height: 115px">
                <vue-qr
                  :size="115"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="codeUrl"
                />
              </div>
              <div
                class="content paramscode_id"
                style="text-align: center;line-height: 0.65rem;margin-top: 5px;"
              >
                {{handle}}
              </div>
            </div>
          </div>

          <div style="clear: both" />

        </div>
        <div class="ewmRightBox" style="min-height: 170px">
          <img src="../../../assets/images/coustomPage/lan/arrowCode.png" alt="" class="arrowImgs">
          <img src="../../../assets/images/coustomPage/lan/bigGz.png" alt="" class="tipGzImgs">
          <p class="titleSurpEwm" style="color: #c22131">扫码有惊喜</p>
          <p class="ewmFiveWordsOne">识别二维码</p>
          <p class="ewmFiveWordsTwo">领取不定时福利</p>
        </div>
        <div style="clear: both" />
      </div>
      <div class="identBox" style="background:transparent">
        <div id="tipsBox" style="background-color: #fff;border: 1px solid #aaa;padding: 10px;">
          <p
            class="identWords fontMiddle fontWeight"
            style="font-size: 0.66rem;color: #000;"
          ><span
            style="background-color: #393937;padding: 0px 4px;border-radius: 17px;color: #fff;"
          >1</span>
            比对实物标签与照片：</p>
          <p
            class="identWords fontSmall"
            style="text-align: left;padding-left: 10px;color: #000;font-size: 0.66rem;"
          >
            <span style="background-color: #393937;padding: 1px 5px;border-radius: 17px;color: #fff;">2</span>
            观察图片中的文字、位置、编码是否相符。</p>
          <p class="identWords fontMiddle fontWeight" style="color: #c22131;line-height: 25px;font-weight: 700;">
            以上特征均相符，则是真品，否则谨防假冒。</p>
        </div>

        <div class="showTitBox" :style="data.quStatus==0?'padding: 0.61rem 0.2rem;':'padding: 0.1rem 0.2rem;'">
          <div
            class="identMaBox5"
          >
            <img src="../../../assets/images/coustomPage/lan/interImg.png" alt="" class="interFiveImg">
            <p class="showIdenWord fontSmall fontWeight codeTitls">
              工业互联网标识码：</p>
            <p class="showIdenWord fontMiddle fontWeight pCode">{{handle}}</p>
          </div>
          <p
            class="identWords fontWeight"
            style="font-size: 12px;color: #aaa;line-height: 20px;width:100%;padding-left: 0.5rem;position: relative;z-index: 9"
          >
            由国家工信部授权工业互联网标识解析二级节点(86.122)签发</p>
          <img
            src="../../../assets/images/coustomPage/lan/grayBackImg.png"
            alt=""
            style="width: 100%;position: absolute;left: 0;top: 0;height: 100%;z-index: 1"
            v-if="data.quStatus == 0"
          >
          <div
            class="zuImg"
            style=""
            v-if="data.quStatus == 0"
          >
            <img
              src="../../../assets/images/coustomPage/lan/grayIcons.png"
              alt=""
              style="width: 50px;height: auto;vertical-align: middle"
            >
            <span style="font-size: 12px;color: #c22131;transform: scale(0.9)"> 防伪数据已接入国家工信部【星火链网】</span>
          </div>
        </div>

        <div style="height: 15px;width: 100%" v-if="data.quStatus == 0" />
        <p
          class="identWords fontSmall"
          style="text-align: left;line-height: 35px;color: #000;padding-left: 15px;background-color: #f8eaed;width:90%;"
        >
          第一次查询时间为：<span class="codeTime" style="color: #f00;">---</span></p>
        <p
          class="identWords fontSmall"
          style="text-align: left;line-height: 35px;color: #000;padding-left: 15px;background-color: #f8eaed;margin-top: 10px;width:90%;"
        >
          第一次查询地点为：<span class="codeAddress" style="color: #f00;">---</span></p>
        <p
          class="identWords fontBig fontWeight"
          style="text-align: left;font-size: 0.7rem;color: #000;margin-top: 10px"
        >
          <img
            src="../../../assets/images/coustomPage/lan/query.png"
            alt=""
            style="width: 20px;vertical-align: middle;margin-right: 5px; max-height:20px;"
          >
          现在是第<span class="codeNumber" style="color: #f00;">---</span>次查询
        </p>
        <p class="tipsTitle" style="color: #000;">*若查询扫码次数过多，标识码可能被盗用，谨防假冒产品！</p>
      </div>
    </div>

  </div>
</template>

<script>

import VueQr from 'vue-qr'
// import $ from 'jquery'
export default {
  name: 'ScanOne',
  components: {
    VueQr
  },
  props: {
    data: {
      type: Object
    },
    handleLink: String,
    handle: String,
  },
  data() {
    return {
      codeUrl: '',
    }
  },
  methods: {
    getQueryString(name, href) {
      // eslint-disable-next-line no-sparse-arrays
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(href) || [, ''])[1].replace(/\+/g, '%20')) || null
    },
    setCode() {
      if(this.handleLink) {
        this.codeUrl = this.handleLink
      }else {
        this.codeUrl = 'https://api.86122m.com/h?h=aaamz'
      }
    },
    getCodeData(id) {
      console.log(`根据标识码：${id}发送请求`)
    }
  },
  mounted() {
    this.setCode()
  }
}
</script>

<style scoped>
  .smIconImgs {
    width: 28%;
    height: auto;
    display: block;
    margin: 50px auto 10px;
  }

  .tipThImgs {
    width: 90%;
    height: auto;
    display: block;
    margin: 15px auto 10px;
  }

  .titleSurpMzEwm {
    text-align: center;
    font-size: 0.8rem;
    width: 100%;
    font-weight: 700;
  }

  .tipNumImgs {
    width: 20px;
    vertical-align: middle;
    margin: 0 5px 0 0;
  }

  .colorshow {
    background-image: linear-gradient(to bottom, #caaf73, #fbe3b6, #fbe3b6);
    -webkit-background-clip: text;
    color: transparent;
  }

  .fontSmall {
    font-size: 0.6rem;
  }

  .fontBig {
    font-size: 0.8rem;
  }

  .fontMiddle {
    font-size: 0.7rem;
  }

  .ewmBox {
    width: 100%;
    height: auto;
    padding: 25px 0;
    font-size: 16px;
    /* background: url("../../../assets/images/coustomPage/lan/forFalseBack.png") no-repeat; */
    background-size: 100% 100%;
  }

  .ewmTitleFen {
    font-size: 12px;
    color: #aaa;
    text-transform: uppercase;
    line-height: 1rem;
    text-align: center;
  }

  .ewmTitle {
    font-size: 1.16rem;
    text-align: center;
    line-height: 1.9rem;
    color: #fff;
    font-weight: 700;
    margin-bottom: 0;
  }

  .showEwmBox {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
  }

  .ewmLeftFiveBox {
    width: 50%;
    height: auto;
    float: left;
    position: relative;
  }

  .imgFourLeft {
    width: 100%;
  }

  .imgFourRight {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 21px;
    margin: auto;
  }

  .chTitle {
    font-size: 12px;
    width: 108px;
    text-align: left;
    line-height: 0.54rem;
    transform: scale(0.66);
    margin-left: -17px;
    font-weight: 700;
  }

  .chWebTitle {
    font-size: 12px;
    width: 108px;
    text-align: left;
    line-height: 0.5rem;
    transform: scale(0.66);
    margin-left: -10px;
    font-weight: 700;
  }

  .phoneFiveImg {
    width: 81%;
    /* height: auto; */
    display: block;
    /* float: left; */
    margin: 30px auto 0;
  }

  .ewmBackImg {
    width: 96%;
    height: auto;
    max-height: 2.5rem;
    display: block;
    margin: 0.1rem auto;
  }

  .setEwmBox {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    margin: auto;
  }

  .prefixEwmImg {
    display: block !important;
    margin: auto;
    width: 125px;
    height: 125px;
    background-color: #ccc;
  }

  .content {
    font-size: 12px;
    color: #000;
    line-height: 1.25rem;
  }

  .ewmRightBox {
    width: 50%;
    height: auto;
    min-height: 250px;
    float: left;
    position: relative;
  }

  .arrowImgs {
    width: 25%;
    height: auto;
    position: absolute;
    left: 14%;
    top: 64px;
  }

  .tipGzImgs {
    width: 70%;
    height: auto;
    position: absolute;
    left: -18%;
    top: 94px;
  }

  .titleSurpEwm {
    position: absolute;
    left: 0;
    top: 80px;
    text-align: center;
    font-size: 0.8rem;
    width: 97%;
    font-weight: 700;
  }

  .EwmWord {
    position: absolute;
    left: 0;
    top: 52px;
    text-align: center;
    font-size: 0.99rem;
    color: #fff;
    width: 82%;
    letter-spacing: 2px;
  }

  .trageImg {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 12px solid #ffbbbb;
    position: absolute;
    left: 0;
    bottom: 65px;
  }

  .ewmFiveWordsOne {
    position: absolute;
    left: 59px;
    bottom: 33px;
    text-align: left;
    font-size: 0.5rem;
    width: 60%;
    color: #aaa;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .ewmFiveWordsTwo {
    position: absolute;
    left: 59px;
    bottom: 15px;
    text-align: left;
    font-size: 0.5rem;
    width: 65%;
    color: #aaa;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .identBox {
    width: 90%;
    height: auto;
    background-color: #bb7777;
    margin: 15px auto 15px;
  }

  .identWords {
    text-align: left;
    line-height: 30px;
    padding-left: 10px;
    color: #000;
  }

  .interFiveImg {
    width: 40px;
    max-height: 40px;
    float: left;
    margin-right: 10px;
  }

  .showIdenWord {
    float: left;
    text-align: left;
    line-height: 20px;
  }

  .codeTitls {
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 0.6rem;
    width: 70%;
    margin-right: 25px;
    float: left
  }

  .pCode {
    margin: 0;
    padding: 0;
    color: #5696d0;
    padding-right: 10px;
    font-weight: 700;
  }

  .zuImg {
    height: auto;
    line-height: 20px;
    margin: 5px auto;
    position: relative;
    z-index: 9;
    padding-left: 0.5rem;
    text-align: left;
  }

  /*二维码展示*/
  .ewmDetailsBox {
    width: 100%;
    height: auto;
    background-color: #ecf2e6;
    padding: 0.3rem;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .ewmDetailsBox .detEwmBox {
    width: 100%;
    height: auto;
    border: 1px dashed #4a9810;
    padding: 15px 0;
  }

  .detEwmBox .ewmForTitle {
    font-size: 1.2rem;
    color: #127604;
    text-align: center;
    line-height: 2.6rem;
    margin-bottom: 0.4rem;
    letter-spacing: 3px;
  }

  .detEwmBox .ewmShowTitle {
    font-size: 0.76rem;
    text-align: center;
    color: #000;
    line-height: 1.2rem;
  }

  .detEwmBox .ewmSignBox {
    width: 100%;
    height: auto;
    margin: 0.6rem auto 0.4rem;
    overflow: hidden;
  }

  .ewmSignBox .topSign {
    width: 100%;
    font-size: 0.7rem;
    font-weight: 700;
    text-align: center;
  }

  .topSign .iconImg {
    width: 1.2rem;
    vertical-align: middle;
    margin-right: 0.4rem;
  }

  .ewmSignBox .prefixEwm3Img {
    width: 7.65rem;
    height: 7.65rem;
    display: block;
    margin: 5px auto 0;
  }

  .ewmSignBox .contentTit {
    color: #fff;
    text-align: center;
    line-height: 1.7rem;
    display: table-cell;
    vertical-align: middle;
    width: 15rem;
  }

  .ewmSignBox .contentTit span {
    font-size: 0.45rem;
    padding: 0.4rem 2.9rem;
    background-color: #127604;
  }

  .showTitBox {
    position: relative;
    margin: 15px 0 0;
    padding: 0.61rem 0.2rem;
  }

  .identMaBox5 {
    margin: 10px 0 5px;
    position: relative;
    z-index: 9;
    width: 90%;
    height: 45px;
    padding-left: 7px;
  }
  .identMaBox5:after{
    content: '';
    width: 90%;
    height: 2px;
    background: transparent;
    border-bottom: 1px dashed #239eff;
    position: absolute;
    left: 10px;
    bottom: -3px;
  }

  .interMaImg {
    width: 2rem;
    margin-right: 10px;
    position: absolute;
    left: 23%;
    top: 0.6rem;
  }

  .showIdenWord3 {
    position: absolute;
    left: 38%;
    top: 10px;
    text-align: left;
    line-height: 25px;
  }

  .tipsTitle {
    font-size: 0.6rem;
    color: #000;
    width: 104%;
    margin: 0 auto 5px;
    text-align: center;
    transform: scale(0.9);
    padding: 10px 0 5px;
  }

  .statusBox {
    width: 100%;
    height: auto;
    padding: 15px;
    box-sizing: border-box;
    display: none;
    position: relative;
  }

  .openForBox, .openUnForBox {
    position: absolute;
    left: 25px;
    top: -5px;
    display: none;
  }

  .staOpenImg, .staUnOpenImg {
    width: 80px;
    height: auto;
  }

  .openWords {
    font-size: 0.99rem;
    color: #ff0000;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    margin-top: 10px;
  }

  .openTips {
    font-size: 0.76rem;
    color: #7d7d7d;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  .staProImg {
    width: 96%;
    height: auto;
    display: block;
    margin: 15px auto 5px;
  }

</style>
