<template>
  <div :style="styleObject">
    <i class="fas" :class="[item.textIcon]" />
    {{ item.content }}
  </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleObject() {
      return {
        padding: this.item.paddTop + 'px' + ' ' + this.item.paddLeft + 'px',
        textAlign: this.item.align,
        fontSize: this.item.fontSize + 'px',
        fontWeight: this.item.weight,
        backgroundColor: this.item.bgColor,
        color: this.item.textColor
      }
    }
  }
}
</script>
