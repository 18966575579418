<template>
  <div class="conWrap" :style="styleObject">
    <div
     v-for="(listItem, index) in item.list" :key="listItem.label+index"
     :style="{width: wid + '%'}"
     >
      <span class="label">{{listItem.label}}</span>
      <span>{{listItem.content}}</span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.conWrap{
  margin: 0 10px;
  text-align: left;
  > div {
    display: inline-block;
    padding: 15px 0;
    font-size: 12px;
    line-height: 20px;
    vertical-align: text-top;
    > span.label {
      font-weight: bold;
    }
  }
}
</style>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleObject() {
      return {
        borderBottom: `${this.item.isShowLine ? '1px' : '0px'} dotted #1D211D`,
      }
    },
    wid() {
      return Math.floor(100/this.item.list.length)
    }
  }
}
</script>
