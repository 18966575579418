<template>
  <div class="telBtn1" :style="{margin: `${item.paddTop}px auto`, background: `${item.bgColor}`}">
		<img class="icon" :src="item.icon" alt="">
    <div :style="{'fontSize': `${item.textSize}px`, color: `${item.textColor}`}">{{item.text}}</div>
  </div>
</template>
<script>

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
    },
    methods: {

    }
}
</script>
<style scoped>
.telBtn1{
  /* background: linear-gradient(90deg, #009DEE, #13EACE); */
  border-radius: 45rpx;
  width: 100%;
  
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  //border-radius: 30px;
  padding: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}
</style>