<template>
  <div>
    <div class="aloneTalkWrap">
        <template v-if="item.btnStyle === 1">
          <div class="zx_btn1" :style="{fontSize:item.fontSize+'px', color: item.color, backgroundColor: item.bgColor,paddingTop: item.paddTop+'px', paddingBottom: item.paddBottom+'px'}" :dataKey="item.zx_key">
            <img class="zx_icon" :style="{width:item.fontSize+'px', height:item.fontSize+'px'}" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/251646961619_.pic.jpg" />{{item.text}}
          </div>
        </template>
      </div>
  </div>
</template>
<style scoped>
.aloneTalkWrap .zx_btn1{
  width: 100%; 
  display: flex; 
  justify-content: center; 
  align-items: center;
  line-height: 25px;
  font-size: 14px;
}
</style>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    
  },
  methods: {
  }
}
</script>
