<template>
  <div>
    <div class="expertTalkWrap">
        <img
          :src="item.bgImg"
          class="singleImg"
          :style="{width: '100%'}"
        />
        <div class="udiTit">UDI码:</div>
        <div class="udiString">
          <p v-for="(listItem, index) in item.contents" :key="index">
            {{listItem.content}}
          </p>
        </div>
        <template v-if="item.btnStyle === 1">
          <div class="scan_btn1" :style="{'left': item.left+getUnitText(item.unit), 'top': item.top+getUnitText(item.unit)}">
            <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/454c68f12252d537cb212e31c33c319f.png" />
          </div>
        </template>
        <!-- <template v-if="item.btnStyle === 2">
          <div class="zx_btn1" :style="{'left': item.left+getUnitText(item.unit), 'top': item.top+getUnitText(item.unit)}">
            {{ item.btnText }}
          </div>
        </template> -->
      </div>
  </div>
</template>
<style scoped>
  .expertTalkWrap{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    border: 1px solid transparent;
    display: block;
    position: relative;
  }
  .zx_btn1{
    width: 100px;
    height: 30px;
    border: 1px solid #f00;
    text-align: center;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #6D7F9B;
    line-height: 30px;
    vertical-align: middle;
    box-shadow: 2px 2px 2px #E9ECF2;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    bottom: 0;
  }
  .scan_btn1{
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    bottom: 0; 
  }
  .scan_btn1 img {
    width: 120px;
  }
  .udiString{
    border: 1px dashed #fff;
    font-size: 12px;
    position: absolute;
    left: 60px;
    top: 10%;
    width: 45%;
    max-height: 140px;
    word-wrap: break-word;
    text-align: left;
    color: #fff;
    padding: 5px;
    /* letter-spacing: 1px; */
  }
  .udiTit{
    position: absolute;
    left: 5px;
    top: 40%;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
  }
  .udiString p {
    margin: 0;
    padding: 0;
    line-height: 15px;
  }
</style>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    
  },
  methods: {
    getUnitText(unit) {
      let str = ''
      if(unit === 1) {
        str="%"
      }else {
        str = 'px'
      }
      return str
    }
  }
}
</script>
