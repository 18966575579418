<template>
  <div
    :style="{textAlign:data.align,fontSize:data.fontSize+'px',fontWeight:data.weight,backgroundColor:data.bgColor,color:data.textColor} "
    class="hiddBox"
  >
    {{ data.bgColor }}
  </div>
</template>
<script>

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
<style>
  .hiddBox{
    display: none;
  }
</style>
