<template>
    <div class="container">
      <div class="containerTit">设备详情</div>
      
      <!--第一块-->
      <div class="blockWrap">
        <div class="blockWrapTit"><span class="dot"></span>{{item.title}}</div>
        <div class="blockWrapInner">
          <div class="borderBox">
            <div class="w50" v-for="item1 in item.mainPar" :key="item1.title">
              <div class="itemTit">{{item1.title}}</div>
              <div class="itemVal">{{item1.content}}</div>
            </div>
          </div>
          <div class="normalBox" v-for="item1 in item.subPar" :key="item1.title">
             <div class="itemTit">{{item1.title}}</div>
             <div class="itemVal">{{item1.content}}</div>
          </div>
        </div>
      </div>
      <!--第二块-->
      <div class="blockWrap blockWrapInner">
        <div class="blockWrapItem" v-for="(item1, index) in item.parDetail" :key="item1.title">
          <div class="normalBox normalBox2">
             <div class="itemTit">{{item1.title}}</div>
             <div class="itemVal">
               <template v-if="item1.type == 1">
                 <span @click="changeVisible(index)">
                   <img v-if="getName(index)" class="jt_down" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/jt.png" />
                   <img v-else class="jt_up" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/jt.png" />
                 </span>
               </template>
               <template v-else>
                 {{item1.value}}
               </template>
             </div>
          </div>
          <template v-if="item1.list">
            <div class="innerCard" v-show="getName(index)">
              <div class="innerCardTop">
                <span>项目</span>
                <span style="padding-right: 20px;">实测值</span>
                <span>要求值</span>
              </div>
              <div class="innerCardBody">
                <div class="innerCardItem" v-for="innerItem in item1.list" :key="innerItem.c1">
                  <span class="c1" v-html="innerItem.c1"></span>
                  <span class="c2" v-html="innerItem.c2"></span>
                  <span class="c3" v-html="innerItem.c3"></span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!--第三块-->
      <div class="blockWrap pd10">
         <div class="normalBox">
             <div class="itemTit">监测报告</div>
             <div class="itemVal"><img class="reportImg" :src="item.reportImg" /></div>
         </div>
      </div>
      <!--第四块-->
      <div class="downBtn">下载报告</div>
    </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Block1Visible: false,
      Block2Visible: false,
      Block3Visible: false,
      Block4Visible: false,
      Block5Visible: false,
      Block6Visible: false,
      Block7Visible: false,
      Block8Visible: false,
      Block9Visible: false,
      Block10Visible: false,
    }
  },
  methods:{
    getName(index) {
      let name = 'Block'+(index+1)+'Visible'
      return this[name]
    },
    changeVisible(index) {
      let name = 'Block'+(index+1)+'Visible'
      this[name] = !this[name]
    }
  }
}
</script>
<style scoped>
.container {
  background: #F8FAFC url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/topBg.png') top center no-repeat;
  background-size: 100% 240px;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
}
.containerTit{
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  padding: 30px 0;
}
.blockWrap{
  width: 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.blockWrapInner{
  padding: 0 10px;
}
.blockWrapTit{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #161B2E;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #E7F5F6;
  padding: 10px 0;
}
.blockWrapTit .dot{
  width: 6px;
  height: 6px;
  background: #4BC3C8;
  border-radius: 50%;
  display: block;
  margin: 0 8px 0 10px;
}
.borderBox {
  border-bottom: 1px dashed #DDE9EA;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemTit{
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #9498A6;
  padding: 5px 0;
}
.itemVal{
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #161719;
  padding: 5px 0;
}
.w50{
  width: 50%;
  box-sizing: border-box;
  text-align: left;
}
.borderBox .w50:nth-child(1) .itemVal{
  color: #1EBAC1;
}
.normalBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.normalBox2{
  padding: 10px 0;
}
.normalBox .itemVal, .normalBox.itemVal{
  padding: 0;
}
.jt_up{
  transform: rotate(180deg)
}
.jt_up, .jt_down{
  width: 15px;
  height: 8px;
}
.downBtn{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  padding: 15px 0;
  background: #4BC3C8;
  box-shadow: 1px 2px 3px 0px rgba(207,235,237,0.82);
  border-radius: 25px;
  margin: 0 auto;
}
.innerCard{
  border-radius: 10px;
  overflow: hidden;
  background: #F8FBFE;
}
.innerCardTop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #E7F5F6;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #161B2E;
  padding: 12px 10px;
}
.innerCardBody{
  padding: 0 8px;
}
.innerCardItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #161719;
  padding: 10px 0;

}
.innerCardItem .c1{
  color: #6E7C7F;
  width: 80px;
  text-align: left;
}
.innerCardItem .c2 {
  width: 80px;
  text-align: center;
}
.innerCardItem .c3{
  width: 130px;
  text-align: right;
}
.reportImg{
  width: 124px;
  display: block;
}
.pd10{
  padding: 10px;
}
</style>
<style>
.innerCardItem .myImg {
  width: 37px!important;
  height: 19px!important;
}
</style>