<template>
  <div :style="styleObject">
    {{ item.content }}
  </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleObject() {
      return {
        margin: this.item.marginTop + 'px' + ' ' + this.item.marginLeft + 'px',
        padding: this.item.paddTop + 'px' + ' ' + this.item.paddLeft + 'px',
        textAlign: this.item.align,
        fontSize: this.item.fontSize + 'px',
        color: this.item.textColor,
        borderBottomColor: this.item.lineColor,
        borderBottomWidth: this.item.isShowLine ? '1px' : '0px',
        borderBottomStyle: 'dotted',
        fontWeight: this.item.fontWeight,
      }
    }
  }
}
</script>
