<template>
<div>
  <div class="XhCertificateWrap" :style="[{backgroundColor:item.bgColor},{ padding: item.paddTop+'px' +' '+ item.paddLeft+'px' },{ backgroundImage: `url(${item.bgUrl})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
    <div class="assCertificateBox">
            <div class="logoBox">
                <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhLogo.png" />
            </div>
            <div class="titleBox">
                <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/mainTit.png" />
            </div>
            <div class="mainMsgBox">
                <div><span>会员单位：</span>{{ item.depName }}</div>
                <div><span>证书编号：</span> {{ item.ceNumber}} </div>
                <div><span>分支机构：</span> {{ item.branch}} </div>
                <div><span>会员级别：</span> {{ item.level}}</div>
            </div>
            <div class="xhModule xhModule1">
                <img class="xhFh" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhFh.png" />
                <img class="xhNum" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhNum1.png" />
                <!-- <img class="titleImg" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhNum1Tit.png" /> -->
                 <div v-for="(mItem, index) in item.model1" :key="mItem.title">
                    <div class="titleText"><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/comIcon.png" />{{mItem.title}}</div>
                    <img class="myImg1" :src="mItem.img" />
                    <div class="fgx" v-if="index!== item.model1.length-1"></div>
                 </div>
            </div>

            <div class="xhModule xhModule2">
                <img class="xhFh" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhFh.png" />
                <img class="xhNum" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhNum2.png" />

                <div v-for="(mItem, index) in item.model2" :key="mItem.title">
                    <div class="titleText"><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/comIcon.png" />{{ mItem.title }}</div>
                    <img class="myImg1" :src="mItem.img" />
                    <div class="fgx"  v-if="index!== item.model2.length-1"></div>
                 </div>
            </div>


            <div class="xhModule xhModule3">
                <img class="xhFh" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhFh.png" />
                <img class="xhNum" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhNum3.png" />
                <!-- <img class="titleImg" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhNum2Tit.png" /> -->
                <div class="titleText"><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/comIcon.png" /> 联系我们 </div>
                <div class="fgx fgx2"></div>


                <div class="xhModuleInnerItem clearfix">
                    <div class="dotBox">
                        <img class="addIcon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/addressIcon.png" />
                    </div>
                    <span class="text">{{ item.address}}</span>
                </div>
                <div class="xhModuleInnerItem clearfix">
                    <div class="dotBox">
                        <img class="addIcon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/networkIcon.png" />
                    </div>
                    <span class="text">{{item.website}}</span>
                </div>
                <div class="xhModuleInnerItem clearfix">
                    <div class="dotBox">
                        <img class="addIcon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhPhoneIcon.png" />
                    </div>
                    <span class="text">{{item.tel}}</span>
                </div>
                <div class="xhModuleInnerItem clearfix">
                    <div class="dotBox">
                        <img class="addIcon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhMail.png" />
                    </div>
                    <span class="text">{{item.mail}}</span>
                </div>

            </div>
        </div>
  </div>
</div>
</template>

<script>
export default {
  components: { 
 },
  name: "XhCertificate2",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {

  },
  methods: {
    getItem() {
      console.log(this.item)
    },
    downPdf() {

    },
  }
}
</script>
<style>
.clearfix:before,
            .clearfix:after {
                content: " ";
                display: table;
            }

            .clearfix:after {
                clear: both;
            }
            .assCertificateBox{
                /* background: url('./https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/rhxhBg.png') left top no-repeat;
                background-size: 100% 100%; */
            }
            .logoBox{
                text-align: left;
            }
            .logoBox img {
                width: 133px;
                height: 33px;
                margin-top: 18px;
                margin-left: 22px;
            }
            .titleBox img {
                width: 295px;
                height: 82px;
                margin: 46px auto;
                display: block;
            }
            .xhModule{
                background-color: #0F3DBC;
                border-radius: 12px;
                width: 90%;
                margin: 0 auto 45px;
                box-sizing: border-box;
                position: relative;
                padding: 10px;
                border: 5px solid rgba(8, 111, 249, 0.5);
                text-align: left;
            }
            .xhModule1{
                /* background-color: #0F3DBC;
                border-radius: 12px;
                width: 90%;
                margin: 0 auto 45px;
                box-sizing: border-box;
                position: relative;
                padding: 10px;
                border: 5px solid rgba(8, 111, 249, 0.5); */
            }
            .xhModule .xhFh{
                width: 28px;
                height: 25px;
                position: absolute;
                left: 25px;
                top: -22px;
            }
            .xhModule .xhNum {
                width: 54px;
                height: 53px;
                position: absolute;
                top:-5px;
                right: 16px;
            }
            .xhModule .myImg1 {
                width: 100%;
            }
            .xhModule .titleImg{
                height: 27px;
                margin-left: 15px;
                margin-top: 22px;
                margin-bottom: 17px;
            }
            .xhModule .titleText {
                color: #fff;
                font-size: 18px;
                font-family: Alibaba PuHuiTi;
                font-weight: 500;
                vertical-align: middle;
                padding: 10px 0;
            }
            .xhModule .titleText img {
                width: 22px;
                height: 20px;
                vertical-align: middle;
                margin-right: 10px;
            }

            .fgx{
                background-color: rgba(255, 255, 255, 0.3);
                height: 1px;
                width: 100%;
                margin: 25px auto 0;
            }
            .xhModuleInnerBox{
                width: 100%;
                background: rgba(10, 23, 76, 0.4);
                box-sizing: border-box;
                margin-bottom: 20px;
            }
            .xhModuleInnerTit{
                width: 150px;
                height: 35px;
                background: linear-gradient(0deg, #FF8A01, #0C2DAC);
                border-radius: 0px 0px 30px 0px;
                align-items: center;
                color: #fff;
                font-size: 13px;
                font-family: Alibaba PuHuiTi;
                font-weight: bold;
                text-align: left;
                line-height: 35px;
                vertical-align: middle;
            }
            .xhModuleInnerTit img {
                width: 17px;
                height: 17px;
                vertical-align: middle;
                margin-left: 10px;
                margin-right: 10px;
            }

            .xhModuleInnerItem{
                position: relative;
                padding: 10px 0;
            }
            .xhModuleInnerItem .text {
                font-size: 12px;
                font-family: Alibaba PuHuiTi;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 15px;
                float: left;
                width: 80%;
            }
            .dotBox{
                width: 30px;
                float: left;
            }
            .dot{
                margin-left: 7px;
                margin-top: 7px;
                background: linear-gradient(0deg, #FF8A01, #0C2DAC);
                border-radius: 50%;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                display: block;
            }
            .fgx2{
                margin-top: 15px;
            }
            .dotBox .addIcon {
                width: 18px;
                height: 18px;
            }

    .mainMsgBox{
        width: 90%;
        margin: 20px auto;
        min-height: 140px;
        background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/xhzs/suMainBg.png') center top no-repeat;
        background-size: 95% 100%;
        font-size: 14px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #fff;
        display: border-box;
        padding: 10px 10px 10px;
    }
    .mainMsgBox span{
        color: #FEBC71;
    }
    .mainMsgBox > div{
        text-align: left;
        line-height: 23px;
        padding: 3px 0;
    }
    .mainMsgBox > div:before{
      background: #EBB270;
      border-radius: 50%;
      content: "";
      display: inline-block;
      width: 7px;
      height: 7px;
      margin: 0 10px 0 20px;
    }

</style>
