<template>
    <div class="productCodeWrap" :style="[{backgroundColor:data.bgColor},{ padding: data.paddTop+'px' +' '+ data.paddLeft+'px' },{ backgroundImage: `url(${data.boxBg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
      <div class="TopBox">
        <div class="titleBox">
          <img class="logo" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/productCodeMp.png" />箱标码：86.122.1/12346
          <div class="titleBoxList">
            <div class="titleItem">箱标名称：珀莱雅弹力紧致面膜</div>
            <div class="titleItem">产品总数：5</div>
            <div class="titleItem">装箱时间：2022-05-22</div>
            <div class="titleItem">操作人：张丹丹</div>
          </div>
        </div>
      </div>
      <div class="ContentBox">
        <div class="title">
          <span class="active">关联商品</span>
          <span>关联盒标</span>
        </div>
        <div class="contentList">

          <div class="codeBox">
            <div class="codeBoxTit"><img class="logo" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/productCodeMp.png" />产品码</div>
            <div class="codeBoxCon">
              <div class="qrWrap">
                  <vue-qr
                  :size="87"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="codeUrl"
                />
              </div>
              <div class="codeTxt">86.122.1/12346</div>
              <div class="qrButton">访问</div>
            </div>
          </div>

          <div class="codeBox">
            <div class="codeBoxTit"><img class="logo" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/productCodeMp.png" />产品码</div>
            <div class="codeBoxCon">
              <div class="qrWrap">
                  <vue-qr
                  :size="87"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="codeUrl"
                />
              </div>
              <div class="codeTxt">86.122.1/12346</div>
              <div class="qrButton">访问</div>
            </div>
          </div>

          <div class="codeBox">
            <div class="codeBoxTit"><img class="logo" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/productCodeMp.png" />产品码</div>
            <div class="codeBoxCon">
              <div class="qrWrap">
                  <vue-qr
                  :size="87"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="codeUrl"
                />
              </div>
              <div class="codeTxt">86.122.1/12346</div>
              <div class="qrButton">访问</div>
            </div>
          </div>



        </div>
      </div>
    </div>
</template>
<script>
import VueQr from 'vue-qr'
export default {
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      codeUrl:'123'
    }
  },
  components: {
    VueQr
  },
}
</script>
<style scoped>
.TopBox{
  width: 100%;
  height: 178px;
  background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/productCodeBg.png') left top no-repeat;
  background-size: 100% 100%;
  text-align: left;
}
.ContentBox{
  background-color: #fff;
  width: 100%;
  border-radius: 14px;
  position: relative;
  top: -10px;
  box-sizing: border-box;
  padding-top: 10px;
}

.titleBox img.logo{
  width: 22px;
  height: 22px;
  margin-left: 16px;
  margin-right: 8px;
  vertical-align: middle;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}
.titleBox{
  font-size: 16px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  color: #FFFFFF;
  line-height: 22px;
  vertical-align: middle;
  padding-top: 20px;
}
.titleBoxList{
  padding-left: 46px;
  padding-top: 15px;
}
.titleBoxList .titleItem{
  font-size: 13px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #EAF0FF;
}
.ContentBox .title{
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #31353D;
  /* line-height: 34px; */
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.ContentBox .title span{
  /* padding: 10px 10px 0 10px; */
  padding: 0 10px;
   padding-bottom: 10px;
}
.ContentBox .title span.active {
  color:dodgerblue;
  border-bottom: 1px solid dodgerblue;
}


.contentList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.codeBox .codeBoxTit {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #4E596B;
  line-height: 22px;
  vertical-align: middle;
}
.codeBox{
  width: 40%;
  height: 218px;
  background: #F9F9FB;
  border-radius: 8px;
  border: 1px solid #DDE7F4;
  text-align: left;
  padding-top: 13px;
  margin: 5px 4%;
}
.codeBox .codeBoxTit .logo {
  width: 22px;
  height: 22px;
  margin-left: 13px;
  margin-right: 6px;
  vertical-align: middle;
}
.qrWrap{
  background: #EEF2F7;
  border-radius: 8px;
  display: inline-block;
  padding: 8px;
  margin: 12px auto 5px;
}
.codeBoxCon{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
}
.qrButton{
  width: 132px;
  height: 30px;
  background: #5396F0;
  border-radius: 22px;
  font-size: 15px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  text-align: center;
  margin-top: 14px;
}
.codeTxt{
  font-size: 12px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #4E596B;
}
</style>
