<template>
	<div :style="styleObject" class="title">
		<div class="contText"  :style="[{fontSize: item.fontSize + 'px'}, {color: item.textColor}]">{{ item.content }}</div>
		<div class="titleText" :style="{fontSize: item.titleSize + 'px'}">{{item.title}}</div>
		<div :class="[{swtOne: item.style == '1'},{swtOne1: item.style == '2'},{swtOne2: item.style == '3'}]"></div>
		<div :class="[{swtTwo: item.style == '1'},{swtTwo1: item.style == '2'},{swtTwo2: item.style == '3'}]"></div>
		<div :class="[{swtThree: item.style == '1'},{swtThree1: item.style == '2'},{swtThree2: item.style == '3'}]"></div>
	</div>

</template>
<script>
	export default {
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		watch: {
			// item: {
			// 	deep: true, //true为进行深度监听,false为不进行深度监听
			// 	handler(val) {
					
			// 	}
			// }
		},
		computed: {
			styleObject() {
				return {
					margin: this.item.marginTop + 'px' + ' ' + this.item.marginLeft + 'px',
					padding: this.item.paddTop + 'px' + ' ' + this.item.paddLeft + 'px',
					textAlign: this.item.align,
					// fontSize: this.item.fontSize + 'px',
					// color: this.item.textColor,
					borderBottomColor: this.item.lineColor,
					borderBottomWidth: this.item.isShowLine ? '1px' : '0px',
					borderBottomStyle: 'dotted',
					fontWeight: this.item.fontWeight,
				}
			}
		}
	}
</script>
<style scoped>
	.title {
		position: relative;
	}

	.contText {
		position: relative;
		z-index: 99;
	}
	
	.titleText {
		position: relative;
		z-index: 99;
		font-weight: 300;
	}

	.swtOne {
		background-color: #f9d9e6;
		position: absolute;
		right: 30px;
		top: 20px;
		width: 80px;
		height: 16px;
		z-index: 10;
	}

	.swtTwo {
		background-color: #f9d9e6;
		position: absolute;
		left: 40px;
		bottom: 20px;
		width: 120px;
		height: 26px;
		z-index: 10;
	}

	.swtThree {
		background-color: #f9d9e6;
		position: absolute;
		right: 60px;
		bottom: 20px;
		width: 16px;
		height: 16px;
		z-index: 10;
	}

	.swtOne1 {
		background-color: #f9d9e6;
		position: absolute;
		right: 50px;
		top: 50px;
		width: 60px;
		height: 40px;
		border-radius: 40px 40px 0 0;
		z-index: 10;
	}

	.swtTwo1 {
		background-color: #f9d9e6;
		position: absolute;
		left: 50px;
		bottom: 40px;
		width: 50px;
		height: 50px;
		z-index: 10;
		border-radius: 50%;
	}

	.swtThree1 {
		background-color: #f9d9e6;
		position: absolute;
		right: 170px;
		bottom: 30px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		z-index: 10;
	}
	
	.swtOne2 {
		position: absolute;
		right: 70px;
		top: 40px;
		z-index: 10;
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-bottom: 30px solid #f9d9e6;
	}
	
	.swtTwo2 {
		position: absolute;
		left: 30px;
		bottom: 50px;
		z-index: 10;
		width: 0;
		height: 0;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		border-bottom: 40px solid #f9d9e6;
	}
	
	.swtThree2 {
		position: absolute;
		right: 160px;
		bottom: 30px;
		z-index: 10;
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-bottom: 20px solid #f9d9e6;
	}
</style>
