<template>
	<div :style="{background: `url(${item.bgImg})`, padding: `${item.paddTB}px ${item.paddLR}px`, fontFamilf: '微软雅黑'}">
		<img :src="listItem.url"
			:style="{width: listItem.width, marginTop: listItem.marginTop + 'px', marginLeft: listItem.marginLeft + 'px',marginRight: listItem.marginRight + 'px', marginBottom: listItem.marginBottom + 'px'}"
			v-for="listItem in item.picList" :key="listItem.url" />
			

		<template v-if="item.flag ==1 || (!item.flag)">
			<div v-for="listItem in item.contentImg" style="position: relative;text-align: left;" :key="listItem.number">
				<div style="position: absolute;left: 20px;top: 40px;">
					<img :src="listItem.headImg" alt="" width="80px" height="100px"
						:style="{marginTop: listItem.marginTop + 'px', marginLeft: listItem.marginLeft + 'px'}">
					<!-- <div>{{listItem.name}}</div> -->
					<div :style="{fontSize: listItem.dateSize + 'px', lineHeight: listItem.dateHeight + 'px'}">
						有效日期：{{listItem.date}}</div>
					<div :style="{fontSize: listItem.numberSize + 'px', lineHeight: listItem.numberHeight + 'px'}">
						证书编号：<span style="display: inline-block;text-decoration: underline;">{{listItem.number}}</span>
					</div>
				</div>
				<div
					:style="{position: 'absolute',right: listItem.contentRight + 'px',top: listItem.contentTop + 'px',width: '140px',textIndent: '2em',textAlign: 'left', fontSize: listItem.contentSize + 'px'}">
					{{listItem.content}}
				</div>
				<img :src="listItem.url" :style="{width: listItem.width, marginTop: '10px'}" />
			</div>			
		</template>
		<template v-else-if="item.flag==2">
			<div v-for="listItem in item.contentImg" style="position: relative;text-align: left;" :key="listItem.headImg">
				<div style="position: absolute;right: 50px;top: 50px;">
					<img :src="listItem.headImg" alt="" width="40px" height="55px"
						:style="{marginTop: listItem.marginTop + 'px', marginLeft: listItem.marginLeft + 'px'}">
				</div>
				<div class="nameBox">
					<span>{{ listItem.name }}</span>同志：
				</div>
                <div
				    class="contentBox"
					:style="{position: 'absolute',right: listItem.contentRight + 'px',top: listItem.contentTop + 'px', fontSize: listItem.contentSize + 'px', color: '#1A3C6D', lineHeight: '18px'}">
					{{listItem.content}}
				</div>
				<div class="botBox">
					<div class="codeBox">
						<vue-qr
							class="codeImg"
							:size="40"
							:margin="0"
							:auto-color="true"
							:dot-scale="1"
							:text="codeUrl"
							/>       
						<div class="numberBox" :style="{fontSize: '12px', lineHeight: listItem.numberHeight + 'px',marginBottom: '5px'}">
							<div>编号：</div>
							{{listItem.number}}
						</div>
				    </div>
					<div>
						<div style="text-align:right; marginBottom: '5px'">山西省工业互联网协会</div>
						<div style="text-align:right;">{{listItem.date}}</div>
					</div>
				</div>
				<!--背景图-->
				<img :src="listItem.url" :style="{width: listItem.width, marginTop: '10px'}" />
			</div>
             
		</template>
		<div
			:style="{fontSize: item.titleSize + 'px', background: item.bgColor, padding: `10px 0px`, marginTop: '10px'}">
			{{item.title}}
		</div>
		<div style="padding: 0 10px;background: #e3e3e3">
			<img :src="listItem.url" :style="{width: listItem.width,marginBottom: '10px'}"
				v-for="listItem in item.reportImg" :key="listItem.url" />
		</div>
		<div :style="{padding: `10px`, background: item.textBg, color: item.textColor, fontSize: item.textsize + 'px', borderRadius: '10px 10px 0 0'}"
			@click="toShow">
			<div style="display: flex;justify-content: center;align-items: center;">
				<span style="margin-right: 20px;">{{item.text}}</span>
				<img v-if="!show" style="width: 20px;height: 20px;" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/bottomArrow.png" />
				<img v-else style="width: 20px;height: 20px;" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/topArrow.png" />
			</div>
			<div style="width: 40px;height: 2px;margin: 5px auto;background: #971f28;"></div>
		</div>
		<img v-show="show" :src="listItem.url" :style="{width: listItem.width}" v-for="listItem in item.wayImg" :key="listItem.url" />
		<div
			:style="{padding: `10px`, background: item.bottomWordsColor, color: item.wordsColor, fontSize: item.wordsize + 'px'}">
			{{item.bottomWords}}
		</div>


	</div>
</template>
<style lang="scss" scoped>
	.listItemDiv {
		display: inline-block;

		>h5 {
			padding: 26px 0 14px 0;
			margin: 0;
			text-align: center;
		}

		>h6 {
			padding: 12px 0 18px 0;
			margin: 0;
			text-align: center;
		}

		.line {
			height: 2px;
			margin: 0 auto;
			overflow: hidden;
		}

		img {
			display: inline-block;
			width: 100%;
		}
	}
	.avatarPic{
		position: absolute;
		right: 0;
		top: 0;
	}
	.nameBox{
		position: absolute;
		top: 105px;
		left: 50px;
		font-size: 14px;
		font-family: Source Han Serif CN;
		font-weight: 800;
		color: #0066A4;
	}
	.nameBox span {
		padding: 0 10px;
		border-bottom: 1px solid #0066A4;
		vertical-align: center;
	}
	.contentBox{
		padding-left: 50px;
		text-indent: 2em;
	}
	.codeBox{
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
	}
	.botBox{
		position: absolute;
		bottom: 30px;
		left: 50px;
		right: 30px;
		font-weight: 500;
		color: #1D56A6;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		font-size: 12px;
	}
	.codeImg{
	  margin-right: 5px;
	}
	.numberBox{
		word-break: break-all;
		width: 80px;
	}
</style>
<script>
import VueQr from 'vue-qr'
	export default {
		data() {
			return {
				show: false,
				codeUrl:'',
			}
		},
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		components: {
			VueQr
		},
		methods: {
			toShow() {
				this.show = !this.show
			}
		},
		computed: {
			// listWrapObject() {
			//   return {

			//   }
			// }
		},
		
		
	}
</script>
