<template>
  <div>
    <div class="expertTalkWrap">
      <img
        :src="item.bgImg"
        class="singleImg"
        :style="{width: '100%'}"
      />
      <template v-if="item.btnStyle === 1">
        <div class="zx_btn1" :style="{'left': item.left+getUnitText(item.unit)}" :dataKey="item.zx_key">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/icon_kf.png" mode="withFix" />立即咨询
        </div>
      </template>
      <template v-if="item.btnStyle === 2">
        <div class="zx_btn2" :style="{'left': item.left+getUnitText(item.unit)}">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/an.png" mode="withFix"/>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .expertTalkWrap{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    border: 1px solid transparent;
    display: block;
    position: relative;
  }
  .zx_btn1{
    width: 100px;
    height: 30px;
    background: #FFF;
    border: 1px solid #E9ECF2;
    text-align: center;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #6D7F9B;
    line-height: 30px;
    vertical-align: middle;
    box-shadow: 2px 2px 2px #E9ECF2;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    bottom: 0;
  }
  .zx_btn1 img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .zx_btn2{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
  }
  .zx_btn2 img {
    width: 300px;
  }
  
</style>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    
  },
  methods: {
    getUnitText(unit) {
      let str = ''
      if(unit === 1) {
        str="%"
      }else {
        str = 'px'
      }
      return str
    }
  }
}
</script>
