<template>
<div>
    <template v-if="activeTab == null">
          <div class="homeWrap" :class="{'homeClass': activeTab == null}">
            <img class="homeImg" :src="item.homeImg" />
            <div class="btnsBox">
              <div v-for="(tabItem, tabIndex) in item.tabList" :key="tabItem.name" class="btnBlock" @click="changeIndex(tabIndex)">
                {{tabItem.name}}
              </div>
            </div>
          </div>
      </template>
      <template v-else>
        <div v-for="(tabContentItem, tabContentIndex) in item.tabList" :key="tabContentItem.id">
          <div v-show="tabContentIndex === activeTab" :class="{'aniClass': activeTab == tabContentIndex}">
            <div class="childPage">
              <!-- <div class="tabTitle">{{item.tabList[tabContentIndex].name}}</div> -->
              <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zybld/back.png" class="backImg" @click="goBack"/>

              <template v-if="tabContentItem.picList.length === 1">
                <img class="swipeImg" :src="tabContentItem.picList[0].img" />
                <div v-if="tabContentItem.swiperList && tabContentItem.swiperList.length" class="botSwipeWrap">
                      <!--底部轮播图-->
                      <van-swipe
                      :autoplay="3000"
                      indicator-color="white"
                    >
                      <van-swipe-item v-for="bImgItem in tabContentItem.swiperList" :key="bImgItem.img">
                          <van-image
                            width="100%"
                            :src="bImgItem.img"
                            class="vanBotImgs"
                          />
                      </van-swipe-item>
                    </van-swipe>
                </div>
              </template>
              <template v-else-if="tabContentItem.picList.length > 1">
                <!--轮播图-->
                <van-swipe
                  :autoplay="3000"
                  indicator-color="white"
                >
                  <van-swipe-item v-for="pItem in tabContentItem.picList" :key="pItem.img">
                      <van-image
                        width="100%"
                        :src="pItem.img"
                        class="vanImgs"
                      />
                  </van-swipe-item>
                </van-swipe>
              </template>
            </div>
          </div>
        </div>
      </template>
</div>


</template>
<script>
import { Image as VanImage, Swipe, SwipeItem } from 'vant'
export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage
  },
  data() {
    return {
      activeTab: null,
      tagsView: [null],
      test: false,
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeIndex(index) {
      if(this.item.tabList[index].picList && this.item.tabList[index].picList.length) {
        this.activeTab = index
      }else{
        this.activeTab = null
      }
      this.tagsView = [...this.tagsView, this.activeTab]
    },
    goBack() {
      this.tagsView.pop()
      this.activeTab = this.tagsView[this.tagsView.length - 1]
      this.test = true
    }
  }
}
</script>
<style scoped>
.homeWrap{
  position: relative;
}
.homeImg{
  width: 100%;
}
.btnsBox{
  position: absolute;
  left:0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.btnBlock{
  background-color: #e7a568;
  color: #2c1513;
  border-radius: 15px;
  overflow: hidden;
  padding: 3px 6px;
  margin: 0 3px;
  cursor: pointer;
}
.swipeImg{
  width: 100%;
  display: block;
}
.childPage{
  position: relative;
}
.childPage .backImg {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  z-index: 99;
  cursor: pointer;
}

.botSwipeWrap{
  position: absolute;
  bottom:0;
  left: 0;
  right: 0;
}
.vanBotImgs{
  border: 1px solid #2b1307;
}
.aniClass{
  animation: pageAnimation 0.8s cubic-bezier(1,0.5,1,1) 0.1s;
}


@keyframes pageAnimation{
  0%{
   opacity: 0;
   transform: scale(0)
  }
  50% {
    transform: scale(1.1)
  }
  100%{
    opacity: 1;
    transform: scale(1)
  }
}

.homeClass{
  animation: homeAnimation 0.8s ease 0.1s;
}
@keyframes homeAnimation{
   0%{
   opacity: 0;
   transform: scale(1.25)
  }
  100%{
    opacity: 1;
    transform: scale(1)
  }
}
</style>