<template>
  <div :style="`padding: ${item.paddTop}px ${item.paddLeft}px; background-color: ${item.bgColor};`">
   <div class="diy_search" :style="styleObject" >
    <i class="diy_icon el-icon-search" :style="`color: ${item.iconColor}; display: inline-block; margin-top: 5px;`"></i>
    <input
      :placeholder="item.placeholder"
      class="diy_input"
      :style="`text-align: ${item.textAlign}; color: ${item.textColor};`" />
   </div>
  </div>
</template>
<style lang="scss" scoped>
.diy_search{
  border: 1px solid #ccc;
  display: flex;
  .diy_input{
    width: 90%;
    padding: 5px 5px 5px 5px;
    font-size: 12px;
    border: none;
    background-color: transparent;
  }
  .diy_input::-webkit-input-placeholder { /* WebKit, Blink, Edge */    color:   inherit; }

  .diy_input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */   color:    inherit; }

  .diy_input::-moz-placeholder { /* Mozilla Firefox 19+ */   color:    inherit; }

  .diy_input:-ms-input-placeholder { /* Internet Explorer 10-11 */   color:    inherit; }

  .diy_icon{
    font-size: 12px;
    margin: 0 10px;
  }
}

</style>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleObject() {
      let borderRadius = 0
      if(this.item.borderRadius == '0') {
        borderRadius = 0
      }else if(this.item.borderRadius == '1') {
        borderRadius = '5px'
      } else {
        borderRadius = '30px'
      }
      return {
        backgroundColor: this.item.inputBgColor,
        overflow: 'hidden',
        borderRadius: borderRadius,
      }
    }
  },
}
</script>

