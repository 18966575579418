<template>
	<div :style="{background: `url(${item.bgImg})`}">
		<div
			:style="{fontSize: `${item.titleSize}px`, color: item.titleColor,textAlign: item.align,padding: `${item.paddTop}px ${item.paddLeft}px`}">
			{{item.title}}
		</div>
		<div
			:style="{fontSize: `${item.subTitleSize}px`, color: item.subTitleColor,textAlign: item.align,padding: `${item.paddTop}px ${item.paddLeft}px`}">
			{{item.subTitle}}
		</div>
		<!-- <div
      v-for="listItem in item.picList" class="imgTextBlock" :key="listItem.title"
      :style="{padding: `${item.paddTop}px ${item.paddLeft}px`}"
    > -->
		<!-- <div class="listItemDiv"> -->
		<!-- <a :href="listItem.link"> -->
		<img :src="listItem.url"
			:style="{width: listItem.width, marginTop: listItem.marginTop + 'px', marginLeft: listItem.marginLeft + 'px',marginRight: listItem.marginRight + 'px', marginBottom: listItem.marginBottom + 'px'}"
			v-for="(listItem, index) in item.picList" :key="index" />
		<!-- </a> -->
		<!-- </div> -->
		<!-- </div> -->
		<div v-for="(v,index) in item.content" :key="index">
			<div :style="{padding: `${item.paddTop}px ${item.paddLeft}px`, color: '#aaa', textAlign: 'left'}">{{v.text}}
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	.listItemDiv {
		display: inline-block;

		>h5 {
			padding: 26px 0 14px 0;
			margin: 0;
			text-align: center;
		}

		>h6 {
			padding: 12px 0 18px 0;
			margin: 0;
			text-align: center;
		}

		.line {
			height: 2px;
			margin: 0 auto;
			overflow: hidden;
		}

		img {
			display: inline-block;
			width: 100%;
		}
	}
</style>
<script>
	export default {
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		computed: {
			// listWrapObject() {
			//   return {

			//   }
			// }
		},
	}
</script>
