<template>
  <div :style="{backgroundColor: item.bgColor}">
  <!-- <div :style="`background-color: ${item.bgColor}; backgroung-image: url(${item.bgImg[0].url? item.bgImg[0].url : none}); background-size: 100% 100%;`"> -->
    <div
      v-for="listItem in item.picList" class="imgTextBlock" :key="listItem.title"
      :style="{padding: `${item.paddTop}px ${item.paddLeft}px`, flexDirection: listItem.imgAlign}"
      >
      <img :src="listItem.url" :style="{width: listItem.width, borderRadius: listItem.imgRadius + 'px'}" />
      <div :style="{justifyContent: listItem.conWrapAlign}">
		<div class="topBox"></div>
		<div class="bottomBox"></div>
        <div :style="{color: listItem.subTitColor, textAlign: listItem.titleAlign,fontSize: listItem.subTitleSize+'px'}">{{listItem.subTitle}}</div>
        <h6 :style="{textAlign: listItem.titleAlign, color: listItem.titleColor, padding: `${listItem.titPaddTop}px 0`, fontSize: listItem.titleSize+'px'}">{{ listItem.title }}</h6>
        <div class="lineWrap" :style="{textAlign: listItem.titleAlign}"><span :style="{display: listItem.isShowLine? 'inline-block' : 'none', backgroundColor: listItem.lineColor}"></span></div>
        <p :style="{textAlign: listItem.contentAlign, color: listItem.contentColor, fontSize: listItem.contentSize+'px'}">{{ listItem.content }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.imgTextBlock{
  display: flex;
  > img {
    // width: 30%;
    max-height: 165px;
    overflow: hidden;
  }
  > div {
    // width: 70%;
	writing-mode:vertical-lr;
    display: flex;
    padding: 0 10px;
    flex-direction: column;
	
	position: relative;

    > h6 {
      margin: 0;
      line-height: 30px;
    }
    > p {
      margin: 0;
      line-height: 20px;
    }
    .lineWrap{
      > span{
        width: 36px;
        height: 2px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
	> .topBox {
		  position: absolute;
		  top: 0;
		  left: 5px;
		  width: 25px;
		  height: 25px;
		  background: #f9d9e6;
	}
	> .bottomBox {
		  position: absolute;
		  bottom: 15px;
		  width: 20px;
		  height: 20px;
		  background: #f9d9e6;
	}
  }
  
}

</style>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    // listWrapObject() {
    //   return {

    //   }
    // }
  },
}
</script>

