<template>
  <div :style="styleObject">
    <div>
      <div class="subTitle">{{item.subTitle}}</div>
      <div class="title">{{item.title}}</div>
      <template v-if="item.listStyle === 1">
        <div class="productWrapper1">
          <div class="productBlock" v-for="proItem in item.productList" :key="proItem.goodsName" @click="goDetailPage(proItem)" :style="{backgroundColor: `${item.proBgColor}`}">
            <template v-if="item.topIconStyleType!==0">
              <div v-if="item.topIconStyle === 1" class="topIcon1" >{{item.topIconTxt}}</div>
              <div v-if="item.topIconStyle === 2" class="topIcon2" >{{item.topIconTxt}}</div>
              <div v-if="item.topIconStyle === 3" class="topIcon3" >{{item.topIconTxt}}</div>
            </template>
            <div class="imgWrap" :style="[{backgroundImage: `url(${proItem.goodPhoto})`, height:`${item.imgHeight}px`}]"></div>
            <div class="goosName" v-if="item.goodsNameVisible" :style="{color: item.goodsNameColor}">{{proItem.goodsName}}</div>
            <div class="goosPrice" v-if="item.priceVisible" :style="{color: item.priceColor}"><span class="symbol">￥</span>{{proItem.price}}</div>
            <div class="buyButtonWrap" v-if="item.buyButtonStyle!==0">
              <div class="button1" v-if="item.buyButtonStyle===1" :style="{borderColor: item.buyButtonColor, color: item.buyButtonColor}">询问</div>
              <div class="button2" v-else-if="item.buyButtonStyle===2" :style="{borderColor: item.buyButtonColor, backgroundColor: item.buyButtonColor}">询问</div>
              <div class="button3" v-else-if="item.buyButtonStyle===3" :style="{borderColor: item.buyButtonColor, backgroundColor: item.buyButtonColor}">立即抢购</div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="item.listStyle === 2">
        <div class="productWrapper2 clearfix">
          <div class="productBlock" v-for="proItem in item.productList" :key="proItem.goodsName" @click="goDetailPage(proItem)" :style="{backgroundColor: `${item.proBgColor}`}">
            <template v-if="item.topIconStyleType!==0">
              <div v-if="item.topIconStyle === 1" class="topIcon1" >{{item.topIconTxt}}</div>
              <div v-if="item.topIconStyle === 2" class="topIcon2" >{{item.topIconTxt}}</div>
              <div v-if="item.topIconStyle === 3" class="topIcon3" >{{item.topIconTxt}}</div>
            </template>
            <div class="imgWrap" :style="[{backgroundImage: `url(${proItem.goodPhoto})`, height: `${item.imgHeight}px`}]"></div>
            <div class="goosName" v-if="item.goodsNameVisible" :style="{color: item.goodsNameColor}">{{proItem.goodsName}}</div>
            <div class="goosPrice" v-if="item.priceVisible" :style="{color: item.priceColor}"><span class="symbol">￥</span>{{proItem.price}}</div>
            <div class="buyButtonWrap" v-if="item.buyButtonStyle!==0">
              <div class="button1" v-if="item.buyButtonStyle===1" :style="{borderColor: item.buyButtonColor, color: item.buyButtonColor}">询问</div>
              <div class="button2" v-if="item.buyButtonStyle===2" :style="{borderColor: item.buyButtonColor, backgroundColor: item.buyButtonColor}">询问</div>
              <div class="button3" v-else-if="item.buyButtonStyle===3" :style="{borderColor: item.buyButtonColor, backgroundColor: item.buyButtonColor}">立即抢购</div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="item.listStyle === 3">
        <div class="productWrapper3">
          <div class="productBlock" v-for="proItem in item.productList" :key="proItem.goodsName" @click="toMiniProgram(proItem)" :style="{backgroundColor: `${item.proBgColor}`}">
            <template v-if="item.topIconStyleType!==0">
              <div v-if="item.topIconStyle === 1" class="topIcon1" >{{item.topIconTxt}}</div>
              <div v-if="item.topIconStyle === 2" class="topIcon2" >{{item.topIconTxt}}</div>
              <div v-if="item.topIconStyle === 3" class="topIcon3" >{{item.topIconTxt}}</div>
            </template>
            <div class="imgWrap" :style="[{backgroundImage: `url(${proItem.goodPhoto})`,height: `${item.imgHeight}px`}]"></div>
            <div class="rightBox">
              <div class="goosName" v-if="item.goodsNameVisible" :style="{color: item.goodsNameColor}">{{proItem.goodsName}}</div>
              <div class="goosPrice" v-if="item.priceVisible" :style="{color: item.priceColor}"><span class="symbol">￥</span>{{proItem.price}}</div>
              <div class="buyButtonWrap" v-if="item.buyButtonStyle!==0">
                <div class="button1" v-if="item.buyButtonStyle===1" :style="{borderColor: item.buyButtonColor, color: item.buyButtonColor}">询问</div>
                <div class="button2" v-if="item.buyButtonStyle===2" :style="{borderColor: item.buyButtonColor, backgroundColor: item.buyButtonColor}">询问</div>
                <div class="button2" v-if="item.buyButtonStyle===3" :style="{borderColor: item.buyButtonColor, backgroundColor: item.buyButtonColor}">立即购买</div>
                <div class="button3" v-else-if="item.buyButtonStyle===3" :style="{borderColor: item.buyButtonColor, backgroundColor: item.buyButtonColor}">立即抢购</div>
              </div>
            </div>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleObject() {
      return {
        // padding: this.item.paddTop + 'px' + ' ' + this.item.paddLeft + 'px',
        // textAlign: this.item.align,
        // fontSize: this.item.fontSize + 'px',
        // fontWeight: this.item.weight,
        // color: this.item.textColor
        backgroundColor: this.item.bgColor,
        backgroundImage: `url(${this.item.bgUrl})`,
        backgroundImageSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      }
    }
  },
  methods: {
    toMiniProgram(data,url, appId) {
       let {cardId, goodsId} = data
       console.log('cardId', cardId)
       console.log('goodsId', goodsId)
        wx.navigateToMiniProgram({
          appId: appId || "wx19adaa0665cc0c6a", // 要跳转的小程序的appid // wx1c06c4e49bf6040e
          path: `pages/prodDetail/main?goodsId=${goodsId}&cardId=${cardId}&from=111`, // 跳转的目标页面
          // envVersion: 'develop', // 仅在当前小程序为开发版或体验版时此参数有效。如果当前小程序是正式版，则打开的小程序必定是正式版。
          extarData: {
            // goodsId: 2,
            // cardId: 17785,
            // from: 111, // 111 代表小程序标记
          },
          success(res) {},
          fail(res) {
            console.log(res);
          }
        });
    },
    goDetailPage(data) {
      let {cardId, goodsId} = data
      console.log(data)
      // uni.navigateTo({
      //     url: `/pages/productDetail/index?goodsId=${goodsId}&cardId=${cardId}&from=1`
      // });
    }
  }
}
</script>
<style scoped>
  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .subTitle{
    font-size: 12px;
    padding: 10px 0 0 0;
    color: #333;
  }
  .title{
    font-size: 20px;
    padding: 10px 0;
  }
  
  .productBlock{ 
    position: relative;
    overflow: hidden;
    border-radius: 2px;
  }
  .productBlock .imgWrap {
    width: 100%;
    height: 168px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
  }
  /* .productBlock .imgWrap img {
    width: 100%;
    height: 168px;
  } */
  .productBlock .goosName{
    font-size: 12px;
    line-height: 22px;
    width: 100%;
    padding-top: 10px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    height: 40px;
    overflow: hidden;
  }
  .productBlock .goosPrice{
    font-size: 16px;
  }
  .productBlock .goosPrice .symbol {
    font-size: 12px;
  }
  .buyButtonWrap .button1{
    font-size: 12px;
    border: 1px solid #f00;
    color: #f00;
    padding: 2px 8px;
    display: inline-block;
    border-radius: 3px;
  }
  .buyButtonWrap .button2{
    font-size: 12px;
    border: 1px solid #f00;
    background-color: #f00;
    color: #fff;
    padding: 2px 8px;
    display: inline-block;
    border-radius: 3px;
  }
  .button3{
    width: 80%;
    margin: 0 auto;
    border-radius: 27px;
    color: #fff;
    padding: 3px 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }
    .topIcon1{
      position: absolute;
      width: 3.39rem;
      height: 2.4rem;
      background: #ff0000;
      z-index: 0;
      transform-origin: left bottom;
      -webkit-transform-origin: left bottom;
      transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      color: #fff;
      line-height: 3.7rem;
      font-size: 0.6rem;
      text-align: center;
  }
  
  .topIcon2{
    position: absolute;
    height: 1rem;
    width: 3.4rem;
    background: #ff7104;
    top: 1.4rem;
    color: #fff;
    font-size: 0.6rem;
    line-height: 1rem;
    text-align: center;
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  .topIcon3{
    position: absolute;
    width: 2.25rem;
    height: 1.2rem;
    background: #04a6f8;
    z-index: 0;
    text-align: center;
    color: #fff;
    font-size: 0.6rem;
    line-height: 1rem;
  }
  /*排版*/
  .productWrapper1, .productWrapper2, .productWrapper3{
    width: 100%;
  }
  .productWrapper1 .productBlock {
    width: 98%;
    margin: 5px auto;
    box-sizing: border-box;
  }
  .productWrapper1 .productBlock .imgWrap{
    height: 210px;
  }
  .productWrapper2 .productBlock {
    box-sizing: border-box;
    width: 46%;
    margin: 5px 2%;
    display: inline-block;
    float: left;
  }
  .productWrapper3 .productBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .productWrapper3 .productBlock .imgWrap {
    max-width: 60%;
  }
  .productWrapper3 .productBlock .rightBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    padding-right: 10px;
  }
  .productWrapper3 .productBlock .goosName{
    padding-top: 0;
  }
  .productWrapper1 .productBlock .imgWrap{
    background-size: 100% 100%;
  }
  .productWrapper3 .productBlock .imgWrap{
    background-size: 100% 100%;
  }
  
</style>