<template>
  <div :style="{backgroundColor: `${item.bgColor}`}">
    <div
      v-for="listItem in item.picList" class="imgTextBlock" :key="listItem.title"
      :style="{padding: `${item.paddTop}px ${item.paddLeft}px`}"
    >
      <div class="listItemDiv">
        <h5 :style="{fontSize: `${listItem.titleSize}px`, color: listItem.titleColor}">{{listItem.title}}</h5>
        <div class="line" :style="{display: item.isShowLine ? 'block' : 'none', backgroundColor: item.lineColor, width: item.lineWidth+'px'}"></div>
        <h6 :style="{fontSize: `${listItem.subTitleSize}px`, color: listItem.subTitleColor}">{{listItem.subTitle}}</h6>
        <!-- <a :href="listItem.link"> -->
          <img :src="listItem.url" :style="{width: listItem.width}" @click.stop="" />
        <!-- </a> -->
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.listItemDiv{
  > h5 {
    padding: 26px 0 14px 0;
    margin: 0;
    text-align: center;
  }
  > h6 {
    padding: 12px 0 18px 0;
    margin: 0;
    text-align: center;
  }
  .line{
    height: 2px;
    margin: 0 auto;
    overflow: hidden;
  }
}
</style>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    // listWrapObject() {
    //   return {

    //   }
    // }
  },
}
</script>

