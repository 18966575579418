<template>
  <div
    :style="{width: 100+'%',height:item.eleHeight+'px',backgroundColor:item.bgColor }"
  >
  </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
