<template>
	<div>
		<div>
			<div style="display: flex;justify-content: space-between;align-items: center;padding: 10px;">
				<div style="display: flex;justify-content: space-between;align-items: center;">
					<div style="font-size: 18px;font-weight: 600;margin-right: 10px;">
						{{ item.ChinaTitle ? item.ChinaTitle : '主要参数'}}
					</div>
					<div style="font-size: 14px;">{{item.EngTitle ? item.EngTitle : 'THE MAIN PARAMETERS'}}</div>
				</div>
				<div @click="toTraceability" v-show="item.btn" style="padding: 5px 10px;border-radius: 20px;background: #f3a72f;color: #fff;">溯源查询</div>
			</div>
			
			<div v-for="(listItem, index) in item.list" :key="index" style="margin: 0 10px;">
				<div style="display: flex;justify-content: space-between;">
					<template v-if="listItem.label || listItem.content">
						<div style="width: 50%;padding: 10px;" :style="{background: index%2==0?'#e9e9e9':'#fff'}">
							{{listItem.label}}
						</div>
						<div style="width: 50%;padding: 10px;" :style="{background: index%2==0?'#f4f4f4':'#fff'}">
							{{listItem.content}}
						</div>						
					</template>

				</div>
				<div v-if="listItem.text" style="text-align: left;padding: 10px;line-height: 24px;">{{listItem.text}}</div>
			</div>
			<div v-show="item.nutrients.flag" style="border: 1px solid #00affb;margin: 10px;">
				<div v-for="(listItem, index) in item.nutrients.table" :key="index">
					<div :style="{display: 'flex',justifyContent: 'space-between',background: index%2==0?'#ecf8ff':'#fff'}">
						<div style="width: 50%;padding: 10px;">
							{{listItem.name}}
						</div>
						<div style="width: 50%;padding: 10px;">
							{{listItem.weight}}
						</div>
						<div style="width: 50%;padding: 10px;">
							{{listItem.proportion}}
						</div>
					</div>
				</div>
			</div>
			<div :class="item.showBotBorder ? 'botBorder' : ''"></div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: true
			}
		},
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		methods: {
			toTraceability() {
				this.item.show = false
			}
		},
		computed: {

		},
	}
</script>

<style scoped>
.botBorder{
	border-bottom: 1px solid #ccc;
	margin: 0px 10px;
    padding: 10px;
}
</style>
