<template>
  <div class="detailblock1" :style="{backgroundColor: item.bgColor, padding: `${item.paddTop}px ${item.paddLeft}px`}">
    <div :style="{backgroundColor: item.conBgColor}">
      <h6 :style="{color: item.enTitleColor, fontSize: item.enTitleSize + 'px'}">{{item.enTitle}}</h6>
      <template v-if="item.isShowLine">
        <template v-if="item.lineType == 1">
          <div class="split1Div">
            <img src="../../../assets/images/coustomPage/sn/split1.png" />
          </div>
        </template>
        <template v-if="item.lineType == 2">
          <div class="split1Div">----</div>
        </template>
      </template>
      <h5 :style="{color: item.titleColor, fontSize: item.titleSize + 'px'}">{{item.title}}</h5>
      <p v-for="(listItem, index) in item.contents" :key="index" class="content" :style="{color: item.contentColor, fontSize: item.contentSize + 'px'}">{{listItem.content}}</p>
    </div>
  </div>
</template>
<style scoped lang="scss">
.detailblock1{
  h6 {
    padding: 15px 0 5px;
    text-align: center;
    margin: 0;
    font-weight: normal;
  }
  .split1Div{
    margin: 0 auto;
    text-align: center;
  }
  h5{
    padding: 5px 0 10px;
    margin: 0;
    text-align: center;
  }
  p{
    line-height: 20px;
    text-align: center;
    text-indent: 2rem;
  }
  .content{
    text-align: left;
    padding: 0 10px;
  }
}
</style>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
  }
}
</script>
