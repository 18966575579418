<template>
	<div :style="{padding: `${item.paddTB}px ${item.paddLR}px`, fontFamilf: '微软雅黑'}">
		<div :style="{fontSize: item.handleSize + 'px',padding: '10px 0',fontWeight: '600'}">{{item.handle?item.handle:'---'}}</div>
		<div style="display: flex;justify-content: flex-start;padding: 5px;background: #eff9ff;">
			<img style="width: 20px;height: 20px;margin-right: 10px;" :src="item.url?item.url:'https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/interImg.png'" />
			<span :style="{fontSize: item.contentSize + 'px'}">{{item.content}}</span>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	div {
		text-align: left;
	}
</style>
<script>
	export default {
		data() {
			return {
				
			}
		},
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		methods: {
			
		},
		computed: {
			
		},
	}
</script>
