<template>
  <div class="videoWrap" :style="{borderBottom : item.bottomshow ? '5px solid #F0F2F7' : '', paddingTop: item.paddTop + 'px', paddingBottom: item.paddBottom + 'px', paddingLeft: item.paddLeft + 'px', paddingRight: item.paddRight + 'px'}">
    <video class="myVideo" 
    controls="controls" 
    :src="item.src" 
    :width="item.videoWidth? item.videoWidth : '100%'"
    :height="item.videoHeight ? item.videoHeight : 'auto'"
    :poster="item.poster ? item.poster : ''"
    ></video>
  </div>
</template>
<style scoped>
.videoWrap video {
  width: 100%!important;
}
.myVideo{
  width: 100%;
}
</style>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    
  }
}
</script>
