<template>

    <div class="ewmBoxWrap" :style="[{backgroundColor:data.bgColor},{ padding: data.paddTop+'px' +' '+ data.paddLeft+'px' },{ backgroundImage: `url(${data.boxBg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
      <div class="boxInner">
      <img class="tenImg1" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/nlj/wa.png" />
      <img class="tenImg2" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/nlj/fg.png" />
      <img class="tenImg3" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/nlj/gxb.png" />
      <div class="greyBox">
        工业互联网标识码：<span>86.122.188/caraj82</span>
      </div>
      <div class="msgBox">
        <div class="msgBox_1">由国家工信部授权工业互联网标识解析二级节点（太原）签发</div>
        <div class="smallText">*请注意产品包装上的标识码与上方标识码是否一致，谨防假冒！</div>
        <img class="tenImg4" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/nlj/fg.png" />
      </div>
      <div class="scanMsg clearfix">
        <div class="scanMsgLeft">
          <img class="tenImg4" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/nlj/cz.png" />
        </div>
        <div class="scanMsgRight">
            <div class="msg1">现在是第<span class="scanNumber">8</span>次查询</div>
            <div class="msg2">*若查询扫码次数过多，标识码可能被盗用，谨防假冒产品！</div>
        </div>
      </div>
      <div class="scanMsg2">
        <div class="scanText1">第一次查询时间为：<span>XXXXXXXXX</span></div>
        <div class="scanText2">第一次查询地点为：<span>XXXXXXXXX</span></div>
      </div>
      <div>
           <template v-if="data.jumpStyle !== 1">
            <div v-if="data.jumpStyle === 2">
              <img class="jumpButton1" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/8569776c96884d28f9ddaad6767e6c61.png" />
            </div>
           </template>
      </div>
      </div>
    </div>


</template>

<script>

// import VueQr from 'vue-qr'
// import $ from 'jquery'
export default {
  name: 'ScanFive',
  components: {
    // VueQr
  },
  props: {
    data: {
      type: Object
    },
    verState: Number,
    handleLink: String,
    handle: String,
    query: {
      type: String
    },
    h: {
      String
    },
    codeData:{
      type: Object
    }
  },
  data() {
    return {
      codeUrl: '',
      veridicalCode: '', // 防伪码
      errTip: false,
      // verState: 0, // 0 未验证 1 验证成功 2 验证失败
      txmDialogVisible: false,
    }
  },
  methods: {
    setCode() {
      if(this.handleLink) {
        this.codeUrl = this.handleLink
      }else {
        this.codeUrl = 'https://fwapi.lfsnms.com/h?h=aaamz'
      }
    },
    // 点击验证
    toVeridical() {
      if(!this.veridicalCode.trim()) {
        this.errTip = true
        this.$emit('veridicalCode', this.veridicalCode)
      }else {
        this.errTip = false
        // 调接口
        this.$emit('veridicalCode', this.veridicalCode)
      }
    },
    openTxmDialog() {
      this.txmDialogVisible = true
    },
    closeTxmDialog() {
      this.txmDialogVisible = false
    }
  },
  mounted() {
    this.setCode()
  },
  computed: {
    imgStyle(picItem,item) {
      return (n, item) => {
        var wid
        var pos
        var str
        // pos = item.align
        if (n.width) {
          wid = n.width
        } else {
          wid = '100%'
        }
        str = 'width: ' + wid
        if (pos === 'left') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'right') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'center') {
          console.log('pos', pos)
          str = `width: ${wid};margin: 0 auto;`
        }
        return str
      }
    }
  }
}
</script>

<style scoped>

  .ewmBoxWrap {
    width: 100%;
    padding-top: 50px;
    height: auto;
    font-size: 16px;
    /* background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/backHe.png'); */
    background-size: cover;
    position: relative;
  }
  .ewmBox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px 0;
  }

  .ewmRightBox{
    width: 70%;
    height: auto;
    display: border-box;
    /* height: 277px; */
  }
  .ewmRightBox input {
    border: 1px solid #838A9B;
    border-radius: 5px;
    display: inline-block;
    width: calc(95% - 60px);
    height: 27px;
    line-height: 27px;
    /* margin: 5px 5px 5px 1px; */
    padding-left: 5px;
    font-size: 0.6rem;
    text-align: left;
    padding-right: 50px;
    display: border-box;
    border-right: none;
  }
  .ewmRightBox button{
    width: 50px;
    height: 27px;
    text-align: center;
    line-height: 27px;
    font-size: 0.6rem;
    background: #0b667c;
    color: #fff;
    /* margin: 5px 0; */
    border-radius: 5px;
    display: inline-block;
    outline: none;
    border: none;
  }
  .errTip{
    font-size: 12px;
    color: #f00;
    text-align: left;
    line-height: 20px;
    padding-left: 10px;
  }
  .verRes{
    font-size: 12px;
    color: #f00;
    text-align: left;
    line-height: 20px;
    padding-left: 10px;
    overflow: hidden;
  }
  .veridicalTitle{
    font-size: 0.86rem;
    text-align: center;
    position: relative;
    font-weight: 700;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .veridicalTitle:before, .veridicalTitle:after {
    content: '';
    position: absolute;
    top: 50%;
    background: #acacac;
    width: 10%;
    height: 1px;
  }
  .veridicalTitle:before{
    left: 10%;
  }
  .veridicalTitle:after {
    right: 10%;
  }
  .chinaTitle{
    font-size: 1.1rem;
    color: #1F2224;
    text-align: center;
    font-weight: bolder;
    /* margin: 25px 0 0; */
    font-family: cursive;
    line-height: 24px;
  }

  /**风格一**/
  .ewmLeftBox{
    width: 43%;
    margin-left: 2%;
    margin-right: 2%;
    background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bq2%402x.png');
    background-size: 100% 100%;
    height: 210px;
    min-height: 170px;
    display: border-box;
    margin-top: 20px;
  }
  .ewmLeftBox .ewmImgBox {
    margin-top: 80px;
  }
 .topMsg p{
    text-align: left;
    line-height: 30px;
    color: #000;
    padding-left: 15px;
    background-color: #c8d6da;
    width: 96%;
    font-size: 0.66rem;
    margin: 6px auto;
  }
  .topMsg p span {
    color: #055167;
  }
  .centerMsg p{
    text-align: left;
    font-size: 0.7rem;
    line-height: 30px;
    padding-left: 10px;
    color: #000;
  }
  .centerMsg p img{
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .centerMsg p span{
    color: #055167;
    font-size: 1rem;
  }
  .tipsBox
  {
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 20px;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/bjKuang.png') top left no-repeat;
    background-size: 100% 100%;
  }
  .tipsBox p {
    font-size: 0.66rem;
    color: #fff;
    text-align: left;
    line-height: 30px;
    padding-left: 10px;
  }
  .tipsBox p img {
    width: 25px;
    height: 25px;
    vertical-align: top;
  }
  .tipsBox .tipText{
    font-weight: bolder;
  }
  .qklBox {
    margin-top: 10px;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/bjQkl.png') top left no-repeat;
    background-size: 100% 100%;
    padding-left: 20px;
    padding-top: 20px;
  }
  .qklBox .box1{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
  }
  .qklBox .box1 img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: top;
  }
  .qklRightBox .qklMsg1{
    color: #fff;
    font-size: 0.6rem;
    text-align: left;
  }
  .qklRightBox .qklMsg2{
    color: #fff;
    font-weight: 700;
     font-size: 0.6rem;
     text-align: left;
  }
  .qklBox .box2{
    font-size: 12px;
    color: #fff;
    line-height: 20px;
    width: 98%;
    padding: 5px 0 10px;
  }
  /***风格二***/
  .ewmLeftBox2{
    width: 43%;
    margin-left: 2%;
    margin-right: 2%;
    /* background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bq2%402x.png'); */
    background-size: 100% 100%;
    height: 220px;
    min-height: 170px;
    display: border-box;
    margin-top: 20px;
    position: relative;
  }
  .ewmLeftBox2 .yzCode {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  .ewmLeftBox2 .ewmImgBox2 {
    margin-top: 90px;
  }
  .ewmLeftBox .ewmImgBox2 .ewmImgCode2{
    text-align: center;
    font-size: 12px;
    color: #333;
    line-height: 25px;
  }
  .style2_msgBox1{
    font-size: 14px;
    font-weight: 400;
    color: #211F1F;
    text-align: left;
    margin-bottom: 20px;
  }
  .style2_msgBox1 img {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
  }
  .style2_msgBox1 .tipMsg{
    font-size: 12px;
    font-weight: 500;
    color: #211F1F;
    padding-left: 10px;
  }
  .style2_msgBox2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    border-bottom: 1px dashed #0A566D;
    width: 80%;
    position: relative;
  }
  .style2_msgBox2 img {
    width: 30px;
    height: 30px;
  }
  .style2_msgBox2 .rightContent .rightText1{
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #3E362C;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
  }
  .style2_msgBox2 .rightContent .rightText2{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #242326;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
  }
  .style2_msgBox3, .style2_msgBox7{
    font-size: 12px;
    font-weight: 400;
    color: #B49B51;
    line-height: 20px;
    text-align: left;
    padding-left: 10px;
  }
  .style2_msgBox4, .style2_msgBox5{
    font-size: 12px;
    font-weight: 400;
    color: #3E362C;
    padding: 3px 5px;
    text-align: left;
    background: rgba(148, 159, 177, 0.2);
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .red{
    color: #f00;
  }
  .style2_msgBox6{
    font-size: 14px;
    font-weight: 400;
    color: #3E362C;
    text-align: left;
    padding-left: 10px;
  }
  .style2_msgBox6 img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .ewmBoxTitle{
    font-size: 20px;
    text-align: center;
    padding: 20px 0;
  }
  .myBox1{
    font-size: 12px;
    color: #211F1F;
    text-align: left;
    vertical-align: middle;
    padding: 5px 0 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .myBox2{
    font-size: 12px;
    color: #211F1F;
    text-align: left;
    vertical-align: middle;
    padding: 5px 0 0 10px;
  }
  .myBox1 img {
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }

  .myBox2 img {
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }
  .myBox2{
    font-size: 12px;
    color: #211F1F;
  }
  .myBox3 img {
    width: 90%;
  }
  .myboxWrap{
    border: 1px dashed #CCCCCC;
    border-radius: 10px;
    padding: 5px;
    width: 90%;
    margin-top: 120px;
  }
  .myVerBox{
    position: relative;
    /* border: 1px solid #f00; */
    width: 90%;
    padding-left: 5px;
    text-align: left;
  }
  .myVerBox button {
    position: absolute;
    right: 0;
    top: 1px;
    height: 30px;
    background-color: #5092FF;
  }

  /**弹窗控件**/
    .txmBtn{
      width: 95px;
      height: 112px;
      position: absolute;
      right: 0;
      bottom: 10px;
    }
    .txmInner{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .txmDialog{
      position: absolute;
      top: 0;
      left:0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.5);
    }
    .closeImg{
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      overflow-y: scroll
    }
    .txmInner{
      padding-top: 30px;
    }
    .txmInner img {
      max-width: 100%;
    }
    .style2_msgBox2 .jumpButton1{
      position: absolute;
      right: -20%;
      top: 0;
      width: 136px;
      height: 42px;
    }
    .ewmRightBox img{
      width: 80%;
    }
    .ewmRightBox .img1 {
      margin: 30px 0;
    }
    .ewmRightBox .img2 {
      margin-bottom: 30px;
    }


    /**ten */
    .tenImg1{
      width: 80%;
      display: block;
      margin: 0 auto 30px;
    }
    .tenImg2{
      width: 80%;
      display: block;
      margin: 0 auto;
    }
    .tenImg3{
      width: 25px;
      margin: 10px auto;
      display: block;
    }

    .greyBox{
      background: rgba(2, 31, 39, 0.1);
      width: 70%;
      margin: 10px auto;
      padding: 5px;
      color: rgba(62, 54, 44, 1);
      font-size: 14px;
    }
    .msgBox{
      width: 95%;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #B49B51;
      margin: 0 auto;
    }
    .msgBox .msgBox_1 {
      font-size: 12px;
    }
    .msgBox .smallText {
      font-size: 10px;
      padding: 10px 0;
    }
    .msgBox .tenImg4 {
      width: 90%;
      margin: 10px auto;
      display: block;
    }
    .scanMsg {
      clear: both;
      width: 95%;
      margin: 0 auto;
      padding-bottom: 10px;
    }
    .scanMsg .scanMsgLeft {
      float: left;
      width: 20%;
    }
    .scanMsg .scanMsgLeft img {
      margin-top: 10px;
    }
    .scanMsg .scanMsgRight{
      float: left;
      text-align: left;
      width: 80%;
      font-size: 12px;
    }
    .scanMsg .scanMsgRight .msg1 {
      font-size: 14px;
      color: rgba(62, 54, 44, 1);
    }
    .scanMsg .scanMsgRight .msg2{
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #B49B51;
      padding: 5px 0;
    }
    .clearfix:after{

      content:"";

      display:block;

      visibility:hidden;

      clear:both;

      }
      .scanNumber{
        color: #B49B51;
        font-size: 18px;
        font-weight: bold;
      }
      .boxInner{
        padding: 60px 0 0;
      }
      .jumpButton1{
        position: absolute;
        right: 0;
        bottom: 140px;
        width: 136px;
        height: 42px;
    }
    .scanMsg2 {
      width: 80%;
      background: rgba(180, 155, 81, 0.06);
      border: 1px solid #B49B51;
      border-radius: 10px;
      margin: 0 auto;
      text-align: left;
    }
    .scanMsg2 .scanText1 {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #8E8880;
       line-height: 25px;
       padding-left: 10px;
    }
    .scanMsg2 .scanText2 {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #8E8880;
      line-height: 25px;
      padding-left: 10px;
    }
    .scanMsg2 .scanText1 span {
      color: rgba(29, 33, 33, 1);
    }
    .scanMsg2 .scanText2 span {
      color: rgba(29, 33, 33, 1);
    }
    .boxInner{
        padding: 60px 0 30px;
      }
</style>
