<template>
<div>
  <div class="MyReportWrap" :style="[{backgroundColor:item.bgColor},{ padding: item.paddTop+'px' +' '+ item.paddLeft+'px' },{ backgroundImage: `url(${item.bgUrl})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
    <div>
      <span class="content" :style="{color: item.conColor}">报告编号：</span>
      <span class="title" :style="{color: item.titColor}">{{item.reportNumber}}</span>
    </div>
    <div class="reportTitle" :style="{color: item.conColor}">{{item.reportTitle}}</div>
    <div class="reportList">
      <div class="reportBlock" v-for="dItem in item.describeList" :key="dItem.title">
        <span class="title" :style="{color: item.titColor}">{{dItem.title}}：</span>
        <span class="content" :style="{color: item.conColor}">{{dItem.content}}</span>
      </div>
    </div>
    <div class="unitInfo">
        <div class="unitAddr" :style="{color: item.conColor}">{{item.reportUnit}}</div>
        <div class="reportTime" :style="{color: item.titColor}"><span class="title">报告日期：</span><span class="content">{{ item.reportTime }}</span></div>
      </div>
      <div class="pdfWrap">
        <el-button size="mini" icon="el-icon-download" class="pdfButton">报告下载</el-button>
      </div>
    </div>
    <div v-for="(picItem,index) in item.picList" :key="picItem.url + index">
      <div>
        <img
          :width="picItem.width"
          :src="picItem.url"
          style="min-height: 20px; display: block;"
        />
      </div>
    </div>
</div>
</template>

<script>
import { formatDate } from '@/utils/utils.js';
export default {
  name: "PdfShow",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {

  },
  methods: {
    getItem() {
      console.log(this.item)
    },
    downPdf() {

    },
    formatTime(date) {
      if(date) {
        return formatDate(date)
      }else {
        return ''
      }
    }
  }
}
</script>
<style scoped>
.MyReportWrap{
  /* background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/ec6368a65159228c41f8c73fc09ca20b.jpeg'); */
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
}
.MyReportWrap .title{
  font-size: 14px;
  line-height: 35px;
  color: #e9c794;
}
.MyReportWrap .content{
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
}
.MyReportWrap .reportTitle {
  font-size: 40px;
  /* font-weight: bold; */
  padding: 10px 0 20px;
  color: #fff;
  letter-spacing: 20px;
}
.reportList .reportBlock{
  width: 92%;
  background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/0ecdc5cc6e2a66dbfd420b67caa22b9a.png') top left no-repeat;
  background-size: 100% 100%;
  margin: 20px auto;
}
.unitInfo{
  border-left: 2px solid #20a0ff;
  padding-left: 10px;
  text-align: left;
  width: 80%;
  margin: 0 auto;
  vertical-align: bottom;
  line-height: 30px;
}
.pdfButton{
  background: #1631D7;
  color: #fff;
  border: none;
  width: 90%;
  margin: 20px auto 10px;
  height: 50px;
  border-radius: 5px;
}
.unitAddr{
  color: #fff;
}
</style>
