import { render, staticRenderFns } from "./ScanTwo.vue?vue&type=template&id=4cbc822e&scoped=true&"
import script from "./ScanTwo.vue?vue&type=script&lang=js&"
export * from "./ScanTwo.vue?vue&type=script&lang=js&"
import style0 from "./ScanTwo.vue?vue&type=style&index=0&id=4cbc822e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cbc822e",
  null
  
)

export default component.exports