<template>

    <div class="ewmBoxWrap" :style="[{backgroundColor:data.bgColor},{ padding: data.paddTop+'px' +' '+ data.paddLeft+'px' },{ backgroundImage: `url(${data.boxBg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
       <div class="ewmBoxTitle">防伪二维码</div>
       <div class="ewmBox">
         <!--左边-->
          <div class="ewmLeftBox2" :style="{backgroundImage: `url(${data.scanBg})`}">
          <div class="ewmImgBox2">
            <vue-qr
              :size="80"
              :margin="0"
              :auto-color="true"
              :dot-scale="1"
              :text="codeUrl"
            />
          </div>
          <div class="yzCode"></div>
          </div>
        <!--右边-->
        <div class="ewmRightBox">
              <!-- <div class="chinaTitle"><img src="	https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/hgImgs/yinHan.png" /></div> -->
              <div class="veridicalTitle">防伪验证</div>
              <div class="myVerBox">
                <input v-model="veridicalCode" placeholder="请输入验证码" />
                <button @click="toVeridical" :style="{backgroundColor: `${data.btnBgColor}`}">验证</button></div>
          <div style="height: 35px;">
            <div class="errTip" v-if="errTip">请输入验证码进行验证</div>
            <!--验证结果-->
            <div class="verRes">
              <div v-if="verState === 1">
                <span>您好，验证成功，您购买的是正品,请放心使用！</span>
              </div>
              <div v-if="verState === 2">
                <span>您好，验证码无效，请谨防假冒！</span>
              </div>
            </div>
          </div>
          <!--右边底下信息-->
            
        </div>
       </div>
        <!-- <div class="style2_msgBox2">
          <div><img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/interImg.png" /></div>
          <div class="rightContent">
            <p class="rightText1">工业互联网标识码：</p>
            <p class="rightText2">{{handle? handle : '---'}}</p>
          </div>
        </div> -->
        <!-- <div class="style2_msgBox3">工业互联网标识解析二级节点（86.122）签发</div> -->
        <div class="style2_msgBox4">上次查询时间为：<span class="red">-----</span></div>
        <div class="style2_msgBox5">上次查询地点为：<span class="red">-----</span></div>
        <div class="style2_msgBox6"><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/fdj2.png" />
        现在是第<span>--</span>次查询
        </div>
        <div class="myboxWrap">
          <div class="myBox1"><img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/hgImgs/hanOne.png" />对比实物标签与图片</div>
          <div class="myBox2"><img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/hgImgs/hanTwo.png" />观察图片中的文字、位置、编码是否相符</div>
          <div class="tipMsg myBox3">以上特征均相符，则是真品，否则谨防假冒</div> 
        </div>

        <div class="style2_msgBox7">*若查询扫码次数过多，标识码可能被盗用，谨防假冒产品！</div>
        <template v-if="data.kjStyle === 1">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/scan_kj.png" class="txmBtn" @click="openTxmDialog" />
          <div class="txmDialog" v-show="txmDialogVisible">
            <img class="closeImg" @click="closeTxmDialog" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/X%402x.png" />
            <div class="txmInner">
              <div v-for="(picItem,index) in data.picList" :key="picItem.url + index" :style="{width: picItem.width }">
                  <img
                    width="100%"
                    :src="picItem.url"
                    style="min-height: 20px; display: block;"
                  />
              </div>
            </div>
          </div>
        </template>

      </div>

</template>

<script>

import VueQr from 'vue-qr'
// import $ from 'jquery'
export default {
  name: 'ScanFive',
  components: {
    VueQr
  },
  props: {
    data: {
      type: Object
    },
    verState: Number,
    handleLink: String,
    handle: String,
    query: {
      type: String
    },
    h: {
      String
    },
    codeData:{
      type: Object
    }
  },
  data() {
    return {
      codeUrl: '',
      veridicalCode: '', // 防伪码
      errTip: false,
      // verState: 0, // 0 未验证 1 验证成功 2 验证失败
      txmDialogVisible: false,
    }
  },
  methods: {
    setCode() {
      if(this.handleLink) {
        this.codeUrl = this.handleLink
      }else {
        this.codeUrl = 'https://fwapi.lfsnms.com/h?h=aaamz'
      }
    },
    // 点击验证
    toVeridical() {
      if(!this.veridicalCode.trim()) {
        this.errTip = true
        this.$emit('veridicalCode', this.veridicalCode)
      }else {
        this.errTip = false
        // 调接口
        this.$emit('veridicalCode', this.veridicalCode)
      }
    },
    openTxmDialog() {
      this.txmDialogVisible = true
    },
    closeTxmDialog() {
      this.txmDialogVisible = false
    }
  },
  mounted() {
    this.setCode()
  },
  computed: {
    imgStyle(picItem,item) {
      return (n, item) => {
        var wid
        var pos
        var str
        // pos = item.align
        if (n.width) {
          wid = n.width
        } else {
          wid = '100%'
        }
        str = 'width: ' + wid
        if (pos === 'left') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'right') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'center') {
          console.log('pos', pos)
          str = `width: ${wid};margin: 0 auto;`
        }
        return str
      }
    }
  }
}
</script>

<style scoped>

  .ewmBoxWrap {
    width: 100%;
    padding-top: 50px;
    height: auto;
    font-size: 16px;
    /* background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/backHe.png'); */
    background-size: cover;
    position: relative;
  }
  .ewmBox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px 0;
  }

  .ewmRightBox{
    width: 70%;
    height: auto;
    display: border-box;
    /* height: 277px; */
  }
  .ewmRightBox input {
    border: 1px solid #838A9B;
    border-radius: 5px;
    display: inline-block;
    width: calc(95% - 60px);
    height: 27px;
    line-height: 27px;
    /* margin: 5px 5px 5px 1px; */
    padding-left: 5px;
    font-size: 0.6rem;
    text-align: left;
    padding-right: 50px;
    display: border-box;
    border-right: none;
  }
  .ewmRightBox button{
    width: 50px;
    height: 27px;
    text-align: center;
    line-height: 27px;
    font-size: 0.6rem;
    background: #0b667c;
    color: #fff;
    /* margin: 5px 0; */
    border-radius: 5px;
    display: inline-block;
    outline: none;
    border: none;
  }
  .errTip{
    font-size: 12px;
    /* color: #f00; */
    text-align: left;
    line-height: 20px;
    padding-left: 10px;
    color: #fff;
  }
  .verRes{
    font-size: 12px;
    color: #fff;
    text-align: left;
    line-height: 20px;
    padding-left: 10px;
    overflow: hidden;
  }
  .veridicalTitle{
    font-size: 0.86rem;
    text-align: center;
    position: relative;
    font-weight: 700;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
    color: #fae7c4;
  }
  .veridicalTitle:before, .veridicalTitle:after {
    content: '';
    position: absolute;
    top: 50%;
    background: #acacac;
    width: 10%;
    height: 1px;
  }
  .veridicalTitle:before{
    left: 10%;
  }
  .veridicalTitle:after {
    right: 10%;
  }
  .chinaTitle{
    font-size: 1.1rem;
    color: #1F2224;
    text-align: center;
    font-weight: bolder;
    /* margin: 25px 0 0; */
    font-family: cursive;
    line-height: 24px;
  }
  .chinaTitle img {
    width: 109px;
    height: 38px;
  }

  /**风格一**/
  .ewmLeftBox{
    width: 43%;
    margin-left: 2%;
    margin-right: 2%;
    background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bq2%402x.png');
    background-size: 100% 100%;
    height: 210px;
    min-height: 170px; 
    display: border-box;
    margin-top: 20px;
  }
  .ewmLeftBox .ewmImgBox {
    margin-top: 80px;
  }
 .topMsg p{
    text-align: left;
    line-height: 30px;
    color: #000;
    padding-left: 15px;
    background-color: #c8d6da;
    width: 96%;
    font-size: 0.66rem;
    margin: 6px auto;
  }
  .topMsg p span {
    color: #055167;
  }
  .centerMsg p{
    text-align: left;
    font-size: 0.7rem;
    line-height: 30px;
    padding-left: 10px;
    color: #000;
  }
  .centerMsg p img{
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .centerMsg p span{
    color: #055167;
    font-size: 1rem;
  }
  .tipsBox
  {
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 20px;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/bjKuang.png') top left no-repeat;
    background-size: 100% 100%;
  }
  .tipsBox p {
    font-size: 0.66rem;
    color: #fff;
    text-align: left;
    line-height: 30px;
    padding-left: 10px;
  }
  .tipsBox p img {
    width: 25px;
    height: 25px;
    vertical-align: top;
  }
  .tipsBox .tipText{
    font-weight: bolder;
  }
  .qklBox {
    margin-top: 10px;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/bjQkl.png') top left no-repeat;
    background-size: 100% 100%;
    padding-left: 20px;
    padding-top: 20px;
  }
  .qklBox .box1{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
  }
  .qklBox .box1 img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: top;
  }
  .qklRightBox .qklMsg1{
    color: #fff;
    font-size: 0.6rem;
    text-align: left;
  }
  .qklRightBox .qklMsg2{
    color: #fff;
    font-weight: 700;
     font-size: 0.6rem;
     text-align: left;
  }
  .qklBox .box2{
    font-size: 12px;
    color: #fff;
    line-height: 20px;
    width: 98%;
    padding: 5px 0 10px;
  }
  /***风格二***/
  .ewmLeftBox2{
    width: 43%;
    margin-left: 2%;
    margin-right: 2%;
    /* background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bq2%402x.png'); */
    background-size: 100% 100%;
    height: 220px;
    min-height: 170px; 
    display: border-box;
    margin-top: 20px;
    position: relative;
  }
  .ewmLeftBox2 .yzCode {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  .ewmLeftBox2 .ewmImgBox2 {
    margin-top: 90px;
  }
  .ewmLeftBox .ewmImgBox2 .ewmImgCode2{
    text-align: center;
    font-size: 12px;
    color: #333;
    line-height: 25px;
  }
  .style2_msgBox1{
    font-size: 14px;
    font-weight: 400;
    color: #211F1F;
    text-align: left;
    margin-bottom: 20px;
  }
  .style2_msgBox1 img {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
  }
  .tipMsg{
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
    color: #942520;
    text-align: left;
    font-weight: bolder;
    line-height: 30px;
  }
  .style2_msgBox2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    border-bottom: 1px dashed #0A566D;
    width: 80%;
  }
  .style2_msgBox2 img {
    width: 30px;
    height: 30px;
  }
  .style2_msgBox2 .rightContent .rightText1{
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #3E362C;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
  }
  .style2_msgBox2 .rightContent .rightText2{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #242326;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
  }
  .style2_msgBox3, .style2_msgBox7{
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    line-height: 20px;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .style2_msgBox4, .style2_msgBox5{
    font-size: 12px;
    font-weight: 400;
    color: #3E362C;
    padding: 8px 30px;
    text-align: left;
    /* background: rgba(148, 159, 177, 0.2); */
    background: #fdefd5;
    border-radius: 15px;
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .red{
    color: #f00;
  }
  .style2_msgBox6{
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: left;
    padding-left: 10px;
  }
  .style2_msgBox6 img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .ewmBoxTitle{
    font-size: 20px;
    text-align: center;
    padding: 20px 0;
    color: #fae7c4;
  }
  .myBox1{
    font-size: 12px;
    color: #942520;
    text-align: left;
    vertical-align: middle;
    padding: 5px 0 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .myBox2{
    font-size: 12px;
    color: #942520;
    text-align: left;
    vertical-align: middle;
    padding: 5px 0 0 10px;
  }
  .myBox1 img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .myBox2 img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .myBox3 img {
    width: 90%;
  }
  .myboxWrap{
    border-radius: 10px;
    padding: 5px;
    width: 80%;
    height: 90px;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/hgImgs/hgKuang.png') top left no-repeat;
    background-size: 100% 100%;
    margin-left: 10px;
    margin-top: 10px;
  }
  .myVerBox{
    position: relative;
    /* border: 1px solid #f00; */
    width: 90%;
    padding-left: 5px;
    text-align: left;
  }
  .myVerBox button {
    position: absolute;
    right: 0;
    top: 1px;
    height: 30px;
    background-color: #5092FF;
    color: #942520;
  }

  /**弹窗控件**/
    .txmBtn{
      width: 95px;
      height: 112px;
      position: absolute;
      right: 0;
      bottom: 10px;
    }
    .txmInner{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .txmDialog{
      position: absolute;
      top: 0;
      left:0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.5);
    }
    .closeImg{
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      overflow-y: scroll
    }
    .txmInner{
      padding-top: 30px;
    }
    .txmInner img {
      max-width: 100%;
    }
</style>
