<template>
  <div class="proInfo1Wrap" :style="[{backgroundColor:item.bgColor},{ padding: item.paddTop+'px' +' '+ item.paddLeft+'px' },{ backgroundImage: `url(${item.bgUrl})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
    <div class="proInfoTitle">{{item.title}}</div>
    <div class="proInfoBody">
      <div v-for="proItem in item.describeList" :key="proItem.title" class="proInfoBlock">
        <div class="leftTitle">{{ proItem.title }}</div>
        <div class="rightContent">{{ proItem.content }}</div>
      </div>
    </div>
    <div class="handleBlock">
      标识码: 192.168.XX/alskjdhhk
    </div>

  </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {

  },
}
</script>
<style scoped>
.proInfo1Wrap{
  box-shadow: 1px 1px 3px #ccc;
  border-radius: 10px;
  overflow: hidden;
  width: 96%;
  margin: 10px auto;
  box-sizing: border-box;
}
.proInfoTitle{
  font-size: 14px;
  color: '#333';
  background-color: #eee;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
}

.proInfoBody {
  padding-left: 10px;
  box-sizing: border-box;
  padding: 20px 5px 20px 10px;
}

.proInfoBlock {
  position: relative;
  border-left: 1px dashed #add9f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  margin: 3px 0;
}
.proInfoBody .proInfoBlock:first-child::after{
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 10px;
  left: -3px;
  top: 0px;
  bottom: 0;
  background: #fff;
}
.proInfoBody .proInfoBlock:last-child::after{
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 100%;
  left: -3px;
  top: 20px;
  bottom: 0;
  background: #fff;
}
.proInfoBody .proInfoBlock::before{
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #add9f0;
  top: 10px;
  left:-5px;
  background: #fff;
}
.leftTitle{
  font-size: 12px;
  color: #333;
  line-height: 30px;
  padding-left: 10px;
}
.rightContent{
  font-size: 12px;
  color: #969696;
  max-width: 50%;
}

.handleBlock{
  width: 96%;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  background: #fbf8f9;
  border: 1px solid #ccc;
  text-align: center;
  color: #5d87c2;
  margin: 0 auto 10px;
}
</style>
