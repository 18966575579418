<template>
    <div class="myNfcBox" :style="[{backgroundColor:item.bgColor},{ padding: item.paddTop+'px' +' '+ 0 +'px'+ ' '+ item.paddBottom+'px' },{ backgroundImage: `url(${item.boxBg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
            <img :src="item.openImg.url" :style="{ 'width': item.openImg.width, 'top': item.top + '%'}" class="iconImg"/>
            <img :src="item.closeImg.url" :style="{ 'width': item.closeImg.width, 'top': item.top + '%'}" class="iconImg"/>  
            <div class="nfcTextBox" :style="{ 'marginTop': item.marginTop+'px'}">
              <!-- <div :style="{ color: item.openColor, fontSize: item.fontSize+'px'}">{{item.openText}}</div> -->
                <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/986caef2e2a12cb6ec0dc899c1f74fee.png" />
                <span :style="{ color: item.closeColor, fontSize: item.fontSize+'px'}">{{item.closeText}}</span>
                <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/1f877e8c8e71f732294e036748a10216.png" />
            </div>          
    </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    
  },
  methods: {

  }
}
</script>
<style scoped>
.myNfcBox{
  position: relative;
}
.iconImg{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
.nfcTextBox{
  margin-top: 30px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nfcTextBox img {
  max-width: 10%;
  margin: 0 2%;
}
</style>