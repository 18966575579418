<template>
    <diV class="boxWrap">
        <div class="topTitBox">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/blueIcon.png" class="blueIcon1" />
          {{ item.title }}
        </div>

        <div class="blockWrap">
          <div class="block1">
              {{ item.content}}
          </div>
          <div class="block2">
              <div class="gxbBox">
                  <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/nlj/gxb.png" />
              </div>
              <div class="handleBox">
                  <div class="t1">产品标识</div>
                  <div  class="t2">86.122.188/caraj82</div>
              </div>
              <template v-if="item.jumpStyle == 2">
                <img class="jumpButton1" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/8569776c96884d28f9ddaad6767e6c61.png" />
              </template>
          </div>
        </div>

        <div class="topTitBox">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/blueIcon.png" class="blueIcon1" />
          功能特点
        </div>
        <div class="blockWrap">
          <div class="gnListItem" v-for="it in item.gnList" :key="it.content">
              <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/blueIcon2.png" class="blueIcon2" />
              <span>{{it.content}}</span>
          </div>
        </div>

        <div class="topTitBox">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/blueIcon.png" class="blueIcon1" />
          参数
        </div>
        <div class="blockWrap">
          <div class="paWrap">
              <div class="paItem" v-for="it in item.parList" :key="it.title" :style="{width: it.with}">
                  <div class="t1">{{it.title}}</div>
                  <div class="t2">{{it.content}}</div>
              </div>
          </div>
        </div>
        <div class="topTitBox">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/blueIcon.png" class="blueIcon1" />
          资质
        </div>
        <div class="blockWrap">
            <img class="zsImg" :src="item.zsImg" />
        </div>
    </diV>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
}
</script>
<style scoped>


.jumpButton1{
    position: absolute;
    right: -12px;
    bottom: 0px;
    width: 136px;
    height: 42px;
}
.tenImg3{
    width: 25px;
    display: block;
    margin-right: 10px;
}
.boxWrap{
    padding: 20px 10px 10px;
    background: #F8FAFC;
}
.topTitBox{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #161B2E;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.blueIcon1{
    width: 27px;
    height: 18px;
    margin-right: 10px;
}
.blockWrap{
    background: #fff;
    padding: 12px;
    border-radius: 5px;
    box-shadow: 1px 2px 7px 0px rgba(226,226,226,0.82);
    margin-bottom: 30px;
}
.block1{
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #6F7486;
    background: #F0F7FB;
    border-radius: 5px;
    padding: 10px;
}
.block2{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid rgba(236, 239, 243, 1);
    margin-top: 15px;
    padding-top: 10px;
}
.gxbBox{
    width: 60px;
    height: 60px;
    background: rgba(240, 247, 251, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
}
.handleBox{
    text-align: left;
}
.handleBox .t1{
    font-size: 11px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #6F7486;
    margin-bottom: 6;
}
.handleBox .t2{
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #161B2E;
}
.zsImg{
    width: 150px;
    height: 100px;
    border: 6px solid rgba(226, 226, 226, 0.8200);
    display: block;
    margin: 0 auto;
}
.gnListItem{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #161B2E;
  margin: 10px 0;
}
.blockWrap .blueIcon2{
  width: 17px;
  height: 17px;
  margin-right: 5px;
}
.paItem{
  text-align: left;
  overflow: hidden;
}
.paItem .t1{
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #6F7486;
  padding: 10px 0 5px;
}
.paItem .t2{
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #161B2E;
  border-bottom: 1px solid rgba(236, 239, 243, 1);
  padding: 5px 0 10px;
}
.paWrap{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
</style>